// dataService.js
import api from '../../../utils/ApiConfig';

// API pour Recupérer la liste fournisseur avec pagination 

const fetchAllFournisseur = async (nbr, nbPage ,termSearch) => {
    try {
      // Créez un objet avec les paramètres
      const params = {
        page: nbr,
        nbpage: nbPage,
      };
  
      // Ajout correct du paramètre `search[value]`
      if (termSearch) {
        params["search[value]"] = termSearch;
      }
  
      // Construire l'URL avec les paramètres filtrés
      const filteredParams = Object.entries(params)
        .filter(([_, value]) => value !== undefined && value !== "")
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");
  
      const url = `/bo/showAllFournisseur?${filteredParams}`;
  
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };
  
  
// API pour Recupérer la liste fournisseur avec pagination 
const fetchAllBySupplier = async (endpoint,idSupplier,nbr, filtreDate,termSearch,nbPage) => {
    try {
        let url = `/${endpoint}/${idSupplier}?page=${nbr}`;
        if (filtreDate) {
            url += `&${filtreDate}`;
        }
        
        if (termSearch) {
             url += `&search=${termSearch}`;
            // const encodedSearch = encodeURIComponent(termSearch).replace(/%20/g, ' ');
            //  url += `&search=${encodedSearch}`;
        }
        if(nbPage){
            url += `&nbpage=${nbPage}`;
        }
        const response = await api.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching');
        throw error;
    }
};
// API pour Recupérer la liste fournisseur avec pagination 
const convertOrdersToInvoice = async (ids) => {
    try {
        let url = `bo/transformeCommandeF/${ids}`;
       
        const response = await api.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching');
        throw error;
    }
};

// update payment status order
const updateStatusCommande = async (endpoint,id,status) => {
    try {
        let url = `/${endpoint}/${id}`;
        const data={
            statuFacture:status
        }
        const response = await api.post(url,data);
        return response.data;
    } catch (error) {
        console.error('Error fetching');
        throw error;
    }
};
// API pour Ajouter un nouveau fournisseur
const CreateFournisseur = async (fournisseurData) => {   
    try{
        const response = await api.post(`/bo/addFournisseur`,fournisseurData)  
        if (response.status === 200) {   
              return response;
          } else {
            throw new Error(`Failed to add fournisseur . Status: ${response.status}`);
          }
        
    }catch(error){
        console.error('Error add fournisseur data:', error);
        throw error;
    }
}


// API pour Recupérer un fournisseurById
const GetFournisseur = async (IdFournisseur) => {   
    try{
        const response = await api.get(`/bo/showOneFournisseur/${IdFournisseur}`)
        if (response.status === 200) {
            return response;
          } else {
            throw new Error(`Failed to get fournisseur. Status: ${response.status}`);
          }

    }catch(error){
        console.error('Error get fournisseur data:', error);
        throw error;
    }
}


// API pour Mettre à jour les informations d'un fournisseur
const UpdateFournisseur = async (IdFournisseur,fournisseur) => {  
    try{
        const response = await api.post(`/bo/updateFournisseur/${IdFournisseur}`,fournisseur);
        if(response.status === 200){
            return response ;
        }else{
            throw new Error(`Failed to update fournisseur . Status: ${response.status}`);
        }

    }catch(error){
        console.error('Error for Editing fournisseur data:', error);
        throw error;
    }
}

// API pour Archiver un fournisseur
const ArchiveFournisseur = async (IdFournisseur) => {  
    try{
        const response = await api.post(`/bo/archiveFournisseur/${IdFournisseur}`)
    if (response.status === 200) {
        return response;
      } else {
        throw new Error(`Failed to archive fournisseur . Status: ${response.status}`);
      }
    }catch(error){
        console.error('Error archive user data:', error);
        throw error;
    }
}


// Export methods *****************************************************************************************
export {fetchAllFournisseur, CreateFournisseur,fetchAllBySupplier,convertOrdersToInvoice,updateStatusCommande, GetFournisseur, UpdateFournisseur, ArchiveFournisseur}

