import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import ClickBack from "../../../components/buttons/ClickBack";
import { REACT_APP_GOOGLE_MAPS_API_KEY } from "../../../utils/ApiConfig.js";
import PlacesAutocompleteComponent from "./PlacesAutocompleteComponent.jsx";

function EditFicheFournisseur(props) {
  const [isEditing, setIsEditing] = useState(false);
  const [isGMapsLoaded, setIsGMapsLoaded] = useState(false);
  const {
    initialAddress,
    initialaltitudeFournisseur,
    initiallongitudeFournisseur,
    initialEmail,
    initialRaisonSociale,
    initialStatus,
    initialTel,
    initialMontantHT,
    initialCommission,
    handleSubmit,
    apiErrors = {},
  } = props;

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Email invalide").required("Email est requis"),
    etat: Yup.number().required("Etat est requis"),
    TelFournisseur: Yup.number().required("Numéro de Téléphone est requis"),
    RaisonSocial: Yup.string().required("Raison Social est requis"),
    montantHT: Yup.number().required("Tarif de Livraison est requis"),
    commission: Yup.number().required("Commission est requise"),
          // Vérification des adresses
          pickupAdr: Yup.string()
          .required("Adresse requise")
          .test(
            "is-selected-from-suggestions",
            "Vous devez sélectionner une adresse depuis les suggestions !",
            (value, context) => {
              const { altitude_Fournisseur, longitude_Fournisseur } = context.parent;
              return !!(altitude_Fournisseur && longitude_Fournisseur);
            }
         ),
  });

  const searchOptions = {
    componentRestrictions: { country: ["fr", "mc"] },
    types: ["geocode", "establishment"],
  };
  useEffect(() => {
    if (!window.google) {
      const gmapScriptEl = document.createElement("script");
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      gmapScriptEl.async = true;
      gmapScriptEl.defer = true;
      gmapScriptEl.onload = () => setIsGMapsLoaded(true);
      document.body.appendChild(gmapScriptEl);
    } else {
      setIsGMapsLoaded(true);
    }
  }, []);
  return (
    <Container>
      <div className="userFournisseurs">
        <Row className="pt-4 pb-2">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <ClickBack editPage={true} />
            <h1 className="p-1">Modification Fournisseur</h1>
          </Col>
        </Row>
        <Formik
          initialValues={{
            email: initialEmail,
            etat: initialStatus,
            TelFournisseur: initialTel,
            montantHT: initialMontantHT,
            commission: initialCommission,
            pickupAdr: initialAddress,
            altitude_Fournisseur: initialaltitudeFournisseur,
            longitude_Fournisseur: initiallongitudeFournisseur,
            RaisonSocial: initialRaisonSociale,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
            //setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            setFieldError,
            isValid,
            dirty,
          }) => (
            <FormikForm onSubmit={handleSubmit}>
              {/* Information personnelle */}
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title className="pb-3">
                    Informations personnelles :
                  </Card.Title>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label>Adresse e-mail</Form.Label>
                        <Field
                          type="email"
                          name="email"
                          placeholder="Email"
                          className="form-control"
                          disabled={!isEditing}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                        {apiErrors.email && (
                          <div className="text-danger">{apiErrors.email}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formRaisonSociale"
                      >
                        <Form.Label>Raison Sociale</Form.Label>
                        <Field
                          type="text"
                          name="RaisonSocial"
                          placeholder="Raison Sociale"
                          className="form-control"
                          disabled={!isEditing}
                        />
                        <ErrorMessage
                          name="RaisonSocial"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formStatus">
                        <Form.Label>Etat</Form.Label>
                        <Form.Select
                          name="etat"
                          className="form-control"
                          defaultValue={values.etat === 1 ? 1 : 0}
                          onChange={(e) => {
                            setFieldValue("etat", e.target.value);
                          }}
                          onBlur={handleBlur}
                          disabled={!isEditing}
                        >
                          <option value="" disabled>
                            Sélectionnez Etat
                          </option>
                          <option value="1">Active</option>
                          <option value="0">Inactif</option>
                        </Form.Select>
                        <ErrorMessage
                          name="etat"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="form_telfournisseur"
                      >
                        <Form.Label>N° de téléphone Fournisseur </Form.Label>
                        <Form.Control
                          className="form-control"
                          type="text"
                          name="TelFournisseur"
                          placeholder="N° de téléphone"
                          disabled={!isEditing}
                          value={values.TelFournisseur}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.TelFournisseur && !!errors.TelFournisseur
                          }
                        />
                        <ErrorMessage
                          name="TelFournisseur"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {isGMapsLoaded && (
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title className="pb-3">Adresse :</Card.Title>
                    <Row>
                      <PlacesAutocompleteComponent
                        name="pickupAdr"
                        value={values.pickupAdr}
                        setFieldValue={setFieldValue}
                        searchOptions={searchOptions}
                        setFieldTouched={setFieldTouched}
                        handleFieldBlur={() => handleBlur("pickupAdr")} 
                        setFieldError={setFieldError} 
                        isInvalid={
                          touched.pickupAdr && !!errors.pickupAdr
                        }
                        disabled={!isEditing}
                      />
                         <ErrorMessage
                          name="pickupAdr"
                          component="div"
                          className="text-danger"
                        />
                    </Row>
                  </Card.Body>
                </Card>
              )}

              {/* Montants */}
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title className="pb-3">Montants :</Card.Title>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formMontantHT">
                        <Form.Label>Montant HT</Form.Label>
                        <Field
                          type="number"
                          name="montantHT"
                          placeholder="Montant HT"
                          className="form-control"
                          disabled={!isEditing}
                        />
                        <ErrorMessage
                          name="montantHT"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formCommission">
                        <Form.Label>Commission %</Form.Label>
                        <Field
                          type="number"
                          name="commission"
                          placeholder="Commission %"
                          className="form-control"
                          disabled={!isEditing}
                        />
                        <ErrorMessage
                          name="commission"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <div md={6} className="text-end">
                <Button
                  variant="primary"
                  type="submit"
                  hidden={!isEditing || isSubmitting}
                  disabled={isSubmitting}
                >
                  Enregistrer la modification
                </Button>
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => setIsEditing(!isEditing)}
                  hidden={isEditing}
                >
                  Modifier
                </Button>
              </div>
            </FormikForm>
          )}
        </Formik>
      </div>
    </Container>
  );
}

export default EditFicheFournisseur;
