import React, { useMemo, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Row, Col, Button, Badge } from "react-bootstrap";
import ClickBack from "../../components/buttons/ClickBack";

import moment from "moment";
import {
  changeStatus,
  deleteProduct,
  updateProductStatus,
  fetchProductFiltred,
} from "./DataService";
import IconTrash from "../../images/trash.png";
import IconEdit from "../../images/edit.png";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import OkModal from "../../components/modal/OkModal";
import { formatAllType } from "../../utilities";
import Filtre from "../../components/Filtre";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import SearchBar from "../../components/SearchBar";
import DynamicTable from "../../components/DynamicTable";

function ListScreen(props) {
  const [ProductData, setProductData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [nbr, setNbr] = useState(1);
  // const [nbr, setNbr] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  //state to get filter
  const [marque, setMarque] = useState("");
  const [categorie, setCategorie] = useState("");
  const [categorieParente, setCategorieParente] = useState("");
  const [min_price, setMin_price] = useState(0);
  const [max_price, setMax_price] = useState(10000);
  const [min_stock, setMin_stock] = useState("");
  const [max_stock, setMax_stock] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showOkModal, setShowOkModal] = useState(false);
  const [actionType, setActionType] = useState("");
  const [selectedProductId, setSelectedProductId] = useState(null);
  // Reset Filters Function
  const handleResetFilters = () => {
    setMarque("");
    setCategorie("");
    setCategorieParente("");
    setMin_price(0);
    setMax_price(10000);
    setMin_stock("");
    setMax_stock("");
    setSearchTerm("");
    setNbr(1); // Reset to the first page
  };
  //Function to get all product
  const fetchData = async () => {
    try {
      setLoading(true);
      let data = await fetchProductFiltred(
        nbr,
        searchTerm,
        marque,
        categorieParente,
        categorie,
        min_price,
        max_price,
        min_stock,
        max_stock
      );
      setProductData(data?.data);
    } catch (error) {
      console.error("Error fetching Product data:", error);
    } finally {
      setLoading(false);
    }
  };
  //Function to delete product
  const handleDelete = async () => {
    try {
      const response = await changeStatus(selectedProductId);
      if (response.status === 200) {
        setShowOkModal(true);
        fetchData(); // Refresh data after deletion
      } else {
        throw new Error(
          `Échec lors de la suppression du produit: ${response.status}`
        );
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du produit :", error);
    } finally {
      setShowConfirmationModal(false);
    }
  };
  //to confirm modal
  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };
  //to close modal
  const handleOkModalClose = () => {
    setShowOkModal(false);
  };
  //to confirm delete modal
  const handleDeleteClick = (productId) => {
    setSelectedProductId(productId);
    setActionType("delete");
    setShowConfirmationModal(true);
  };

  // Update search term and fetch data
  const handleSearch = (term) => {
    setSearchTerm(term);
    setNbr(1); // Reset to first page
   // fetchData(); // Trigger fetch with updated search term
  };

  useEffect(() => {
    fetchData();
  }, [
    nbr,
    searchTerm,
    marque,
    categorie,
    categorieParente,
    min_price,
    max_price,
    min_stock,
    max_stock,
  ]);
  const columns = useMemo(
    () => [
      {
        Header: "Titre produit",
        accessor: "titre",
        Cell: ({ value }) => (
          <div className="LongText" title={value}>
            {value}
          </div>
        ),
      },
      { Header: "Catégorie", accessor: "product_category.name" },
      { Header: "Référence", accessor: "reference" },
      {
        Header: "Prix HT",
        accessor: "ht",
        Cell: ({ value }) => formatAllType(value),
      },
      { Header: "Marque", accessor: "product_marque.name" },
      {
        Header: "Date de création",
        accessor: "created_at",
        Cell: ({ value }) => <p>{moment(value).format("DD/MM/YYYY")}</p>,
      },
      {
        Header: "Statut",
        accessor: "status",
        Cell: ({ value }) => (
          <Badge className={value === "1" ? "bg-success" : "bg-danger"}>
            {value === "1" ? "Actif" : "Inactif"}
          </Badge>
        ),
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ value }) => (
          <div>
            <a href={`product/edit/${value}`} className="p-2">
              <img src={IconEdit} width="20px" alt="Modifier" />
            </a>
            <a
              href="#"
              className="p-2"
              onClick={() => handleDeleteClick(value)}
            >
              <img src={IconTrash} width="20px" alt="Supprimer" />
            </a>
          </div>
        ),
      },
    ],
    []
  );

  if (!ProductData?.products) {
    return (
      <Container>
        <p>Chargement...</p>
      </Container>
    );
  }

  return (
    <Container>
      <div className="ProductList">
        <p className="h6">Accueil / Produits</p>
        <Row className="pt-4 pb-2">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <ClickBack />
            <h1 className="p-1">Produits</h1>
          </Col>
          <Col
            xs
            lg="2"
            className="d-flex justify-content-end align-items-center"
          >
            <a className="btn btn-primary" href="/product/add">
              Ajouter produit
            </a>
          </Col>
        </Row>
        <Filtre
          marque={marque}
          setMarque={setMarque}
          categorie={categorie}
          setCategorie={setCategorie}
          min_price={min_price}
          setMin_price={setMin_price}
          max_price={max_price}
          setMax_price={setMax_price}
          min_stock={min_stock}
          setMin_stock={setMin_stock}
          max_stock={max_stock}
          setMax_stock={setMax_stock}
          categorieParente={categorieParente}
          setCategorieParente={setCategorieParente}
          handleResetFilters={handleResetFilters}
        />
        <>
          <SearchBar searchTerm={searchTerm} handleSearch={handleSearch} />
        </>
        {loading ? (
          <p className="lead">Chargement...</p>
        ) : (
          <>
            <DynamicTable
              columns={columns}
              data={ProductData?.products}
              link={ProductData?.pagination?.links}
              last_page={ProductData?.pagination?.last_page}
              setNbr={setNbr}
              nbr={nbr}
              total={ProductData?.pagination?.total_items}
              name="product"
            />
          </>
        )}
      </div>
      <ConfirmationModal
        show={showConfirmationModal}
        onClose={handleCloseConfirmationModal}
        onConfirm={handleDelete}
        actionType={actionType}
      />
      <OkModal
        show={showOkModal}
        onClose={handleOkModalClose}
        onConfirm={handleOkModalClose}
      />
    </Container>
  );
}

export default ListScreen;
