// Import necessary dependencies and components
import React, { useMemo, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import {
  Row,
  Col,
  Button,
  ButtonGroup,
  Dropdown,
  Badge,
} from "react-bootstrap";
import ClickBack from "../../components/buttons/ClickBack";
import DynamicTable from "../../components/DynamicTable";
import { fetchUserData, updateUserStatus } from "./dataService";
import IconTrash from "../../images/trash.png";
import IconEdit from "../../images/edit.png";
import IconShow from "../../images/fi_eye.png";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import OkModal from "../../components/modal/OkModal";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import SearchBar from "../../components/SearchBar";
// Define the functional component
function ListScreen(props) {
  // State to hold user data
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(1); // Initial user role
  const [title, setTitle] = useState("Particuliers"); // Initial title
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showOkModal, setShowOkModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [nbr, setNbr] = useState(1);
  const [actionType, setActionType] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [roleConnected, setRoleConnected] = useState(null);

  // Function to fetch user data based on role
  const fetchData = async (role) => {
    try {
      setLoading(true);
      const data = await fetchUserData(role, nbr, searchTerm);
      setUserData(data.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle user role change
  const handleUserRoleChange = (role) => {
    // Update the user role and fetch data for the new role
    setUserRole(role);
    fetchData(role);

    // Update the title based on the user role using a switch statement
    switch (role) {
      case 1:
        setTitle("Particuliers");
        break;
      case 3:
        setTitle("Livreur");
        break;
      case 2:
        setTitle("Entreprise ");
        break;
      case 5:
        setTitle("Admin");
        break;
      default:
        setTitle("Particuliers");
    }
  };

  const updateUserStatusInactive = async () => {
    try {
      const response = await updateUserStatus(selectedUserId);
      if (response.status === 200) {
        setShowOkModal(true);
        fetchData(userRole); // Refresh data after deletion
      } else {
        throw new Error(
          `Échec lors de la suppression du l'utilisateur: ${response.status}`
        );
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du l'utilisateur :", error);
    } finally {
      setShowConfirmationModal(false);
    }
  };

  //to confirm modal
  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };
  //to close modal
  const handleOkModalClose = () => {
    setShowOkModal(false);
  };
  //to confirm delete modal
  const handleDeleteClick = (userId) => {
    setSelectedUserId(userId);
    setActionType("delete");
    setShowConfirmationModal(true);
  };
    // Update search term and fetch data
    const handleSearch = (term,nbr,role) => {
      setSearchTerm(term);
      setNbr(1); // Reset to first page
      //fetchData(role, nbr, searchTerm); // Trigger fetch with updated search term
    }; 
  // useEffect to automatically load data when the component mounts
  useEffect(() => {
    fetchData(userRole, nbr);
    const roleStorage = Number(
      JSON.parse(localStorage.getItem("userData") || "{}")?.role
    );
    setRoleConnected(roleStorage);
  }, [userRole, nbr, searchTerm, roleConnected]); // Empty dependency array means it will run once when the component mounts

  // Memoized columns for the DynamicTable component
  const columns = useMemo(() => {
    return [
      { Header: "ID", accessor: "id" },
      { Header: "Prénom", accessor: "prenom" },
      { Header: "Nom", accessor: "nom" },
      userRole === 3
        ? { Header: "Téléphone", accessor: "user_profil.telephone" }
        : null,
      { Header: "E-mail", accessor: "email" },
      {
        Header: "Etat",
        accessor: "status",
        Cell: ({ value }) => (
          <Badge className={value === 1 ? "bg-success" : "bg-danger"}>
            {value === 1 ? "Active" : "Inactive"}
          </Badge>
        ),
      },
      {
        Header: "Date de création",
        accessor: "created_at",
        Cell: ({ value }) =>
          format(new Date(value), "dd-MM-yyyy", { locale: fr }),
      },
      {
        Header: "Actions",
        Cell: ({ value, row }) => (
          <div>
            <a href={`user/show/${row.original.id}`} className="p-2">
              <img src={IconShow} width="20px" alt="Voir" />
            </a>
            <a href={`user/edit/${row.original.id}`} className="p-2">
              <img src={IconEdit} width="20px" alt="Modifier" />
            </a>
            <a
              href="#"
              className="p-2"
              onClick={() => handleDeleteClick(row.original.id)}
            >
              <img src={IconTrash} width="20px" alt="Supprimer" />
            </a>
          </div>
        ),
      },
    ].filter(Boolean); // Filter out any falsey values
  }, [nbr, userRole]); // Add dependencies if needed

  // Render loading message while data is being fetched
  if (!userData) {
    return (
      <Container>
        <p>Chargement...</p>
      </Container>
    );
  }

  // Render the component
  return (
    <Container>
      <div className="userList">
        {/* Breadcrumb navigation */}
        <p className="h6">Accueil / Utilisateurs / {title}</p>

        {/* Header and filters */}
        <Row className="pt-4 pb-2">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <ClickBack />
            {/* Display dynamic title */}
            <h1 className="p-1">{title}</h1>
            <Dropdown as={ButtonGroup} className="p-2 mb-2">
              <Dropdown.Toggle
                id="dropdown-basic"
                className="UserTypeDropdown"
              />
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleUserRoleChange(1)}>
                  Particuliers
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleUserRoleChange(3)}>
                  Livreur
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleUserRoleChange(2)}>
                  Entreprise
                </Dropdown.Item>
                {roleConnected === 5 && (
                  <Dropdown.Item onClick={() => handleUserRoleChange(5)}>
                    Admin
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col
            xs
            lg="2"
            className="d-flex justify-content-end align-items-center"
            style={{ alignContent: "right" }}
          >
            {/* add button */}
            <Col
              className="d-flex justify-content-end align-items-center"
              style={{ alignContent: "left" }}
            >
              <a className="btn btn-primary" href="/user/add">
                Ajouter Utilisateur
              </a>
            </Col>
          </Col>
        </Row>
          <SearchBar searchTerm={searchTerm} handleSearch={handleSearch} />
        {/* Render the DynamicTable component */}
        {loading ? (
          // Display loader while data is being fetched
          <p className="lead">Chargement...</p>
        ) : (
          <DynamicTable
            columns={columns}
            // setSearchTerm={setSearchTerm}
            data={userData?.data}
            link={userData?.links}
            // next_page_url={userData?.next_page_url}
            // prev_page_url={userData?.prev_page_url}
            last_page={userData?.last_page}
            setNbr={setNbr}
            nbr={nbr}
            total={userData?.total}
            name="user"
          />
        )}
      </div>
      <ConfirmationModal
        show={showConfirmationModal}
        onClose={handleCloseConfirmationModal}
        onConfirm={updateUserStatusInactive}
        actionType={actionType}
      />
      <OkModal
        show={showOkModal}
        onClose={handleOkModalClose}
        onConfirm={handleOkModalClose}
      />
    </Container>
  );
}

// Export the component as the default export
export default ListScreen;
