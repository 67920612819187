import React from "react";
import { Dropdown, ButtonGroup, Col } from "react-bootstrap";

// Define the functional component
function ElementsPerPageDropdown({ nbElement, onNbElementChange }) {
  // Handle selection of number of elements
  const handleSelect = (value) => {
    onNbElementChange(value); // Call the parent handler with the selected value
  };

  return (
    <Col>
    <Dropdown as={ButtonGroup} className="p-2 mb-2">
      <Dropdown.Toggle id="dropdown-basic" className="UserTypeDropdown">
        Nombre d'éléments à afficher : {nbElement}{" "}
        {/* Display the current value */}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => handleSelect(10)}>10</Dropdown.Item>
        <Dropdown.Item onClick={() => handleSelect(25)}>25</Dropdown.Item>
        <Dropdown.Item onClick={() => handleSelect(50)}>50</Dropdown.Item>
        <Dropdown.Item onClick={() => handleSelect(100)}>100</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    </Col>
  );
}

// Export the component
export default ElementsPerPageDropdown;