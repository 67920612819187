// Importation des dépendances et composants nécessaires
import React, { useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Row, Col, Modal, Form, Button, Alert, Badge } from "react-bootstrap";
import ClickBack from "../../components/buttons/ClickBack";
import DynamicTable from "../../components/DynamicTable";
import moment from "moment";
import {
  fetchDeliveryTowerData,
  updateDeliveryData,
  saveDeliveryData,
  deleteDeliveryTower,
  fetchZonesData,
} from "./DataService";
import IconEdit from "../../images/edit.png";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import IconTrash from "../../images/trash.png";
import SearchBar from "../../components/SearchBar";
// Définition du composant fonctionnel
function ListScreen(props) {
  // Initialisation des hooks nécessaires
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("add"); // Ajouter un état pour suivre le mode de la modale
  const [deliveryData, setDeliveryData] = useState(null);
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [selectedDeliveryID, setSelectedDeliveryID] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null); // État pour le message d'erreur
  const [zoneData, setZoneData] = useState(); // État pour la zone
  const [searchTerm, setSearchTerm] = useState("");
  const [nbr, setNbr] = useState(1); //Etat de nombre de page pour la pagination

  //Fonction pour afficher liste du Tour de Livraison
  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await fetchDeliveryTowerData(nbr, searchTerm);
      setDeliveryData(data);
    } catch (error) {
      setError("Erreur lors de la récupération des données de livraison.");
      console.error(
        "Erreur lors de la récupération des données de livraison :",
        error
      );
    } finally {
      setLoading(false);
    }
  };

  //Fonction pour afficher liste des Zones
  const fetchZoneData = async () => {
    try {
      setLoading(true);
      const data = await fetchZonesData();
      setZoneData(data);
    } catch (error) {
      setError("Erreur lors de la récupération des données de zone.");
      console.error(
        "Erreur lors de la récupération des données de zone :",
        error
      );
    } finally {
      setLoading(false);
    }
  };
    // Update search term and fetch data
    const handleSearch = (term) => {
      setSearchTerm(term);
      setNbr(1); // Reset to first page
    };
  useEffect(
    () => {
      fetchData(nbr);
      fetchZoneData();
    },
    [nbr, searchTerm]
  );

  const [newDeliveryData, setNewDeliveryData] = useState({
    datetime_start: "",
    datetime_end: "",
    livraison_heur_debut: "",
    livraison_heur_fin: "",
    zone_id: "",
    status: "",
  }); // État pour les nouvelles données du Tour de livraison

  // Fonction pour gérer le clic sur l'option de la modale
  const handleModalOptionClick = (deliveryID) => {
    // Mettre à jour l'identifiant de livraison sélectionné
    setSelectedDeliveryID(deliveryID);

    // S'assurer que l'ID de livraison et ses propriétés sont valides avant de continuer
    if (deliveryID && deliveryID.original) {
      // Enregistrer le nom de la livraison sélectionnée pour le débogage
      // Définir selectedDelivery avec les données de la livraison sélectionnée
      setSelectedDelivery(deliveryID.original);
    } else {
      // Si l'ID de livraison ou ses propriétés ne sont pas valides, réinitialiser selectedDelivery à null ou à un objet vide
      setSelectedDelivery(null); // ou setSelectedDelivery({})
    }
    // Puis procéder à la définition de showModal et modalMode
    setShowModal(true);
    setModalMode("edit"); // Définir le mode de la modale sur modification
  };
  // Fonction pour ouvrir la modale d'ajout de livraison
  const handleOpenAddDeliveryModal = () => {
    setShowModal(true);
    setModalMode("add"); // Définir le mode de la modale sur ajout
  };
  // Fonction pour mettre à jour les données de livraison
  const handleUpdateDelivery = async () => {
    // Implémentation pour la mise à jour des données du Tour de livraison
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append(
        "Heure de commande à partir de",
        selectedDelivery.datetime_start
      );
      formData.append(
        "Heure de commande jusqu'à",
        selectedDelivery.datetime_end
      );
      formData.append(
        "Heure de début livraison estimé",
        selectedDelivery.livraison_heur_debut
      );
      formData.append(
        "Heure de fin de livraison estimé",
        selectedDelivery.livraison_heur_fin
      );
      formData.append("zone", selectedDelivery.zone_id);
      formData.append("id", selectedDeliveryID.original.id);

      // Extraction des jours et heures à partir des dates fournies
      const startDateTime = new Date(selectedDelivery.datetime_start);
      const endDateTime = new Date(selectedDelivery.datetime_end);
      const debutLivraisonDateTime = new Date(
        selectedDelivery.livraison_heur_debut
      );
      const finLivraisonDateTime = new Date(
        selectedDelivery.livraison_heur_fin
      );

      // Vérification si les dates sont le même jour
      const isSameDay =
        startDateTime.toDateString() === endDateTime.toDateString();

      // Vérification si datetime_start est supérieur ou égal à datetime_end pour le même jour (si applicable)
      if (isSameDay && startDateTime >= endDateTime) {
        throw new Error(
          "L'heure de commande jusqu'à doit être supérieure à l'heure de commande à partir de."
        );
      }

      // Vérification si livraison_heur_debut est supérieur ou égal à livraison_heur_fin pour le même jour (si applicable)
      if (isSameDay && debutLivraisonDateTime >= finLivraisonDateTime) {
        throw new Error(
          "L'heure de fin de livraison estimée doit être supérieure à l'heure de début de livraison estimée."
        );
      }

      // Validation des champs obligatoires
      const response = await updateDeliveryData(
        selectedDeliveryID.original.id,
        selectedDelivery
      );
      if (response.status === 200) {
        handleCloseModal();
        history.go(0);
      } else {
        throw new Error(
          `Échec de la mise à jour de la livraison. Statut : ${response.status}`
        );
      }
    } catch (error) {
      setError(error.message);
      console.error("Erreur lors de la mise à jour de la livraison :", error);
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour ajouter une nouvelle livraison
  const handleAddDelivery = async () => {
    // Implémentation pour l'ajout d'une nouvelle livraison
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append(
        "Heure de commande à partir de",
        newDeliveryData.datetime_start
      );
      formData.append(
        "Heure de commande jusqu'à",
        newDeliveryData.datetime_end
      );
      formData.append(
        "Heure de début livraison estimé",
        newDeliveryData.livraison_heur_debut
      );
      formData.append(
        "Heure de fin de livraison estimé",
        newDeliveryData.livraison_heur_fin
      );
      formData.append("status", newDeliveryData.status);
      formData.append("zone", newDeliveryData.zone_id);
      // Validation des champs obligatoires
      if (
        !newDeliveryData.datetime_start ||
        !newDeliveryData.datetime_end ||
        !newDeliveryData.livraison_heur_debut ||
        !newDeliveryData.livraison_heur_fin ||
        !newDeliveryData.status ||
        !newDeliveryData.zone_id
      ) {
        throw new Error("Tous les champs sont obligatoires.");
      }
      // Extraction des jours et heures à partir des dates fournies
      const startDateTime = new Date(newDeliveryData.datetime_start);
      const endDateTime = new Date(newDeliveryData.datetime_end);
      const debutLivraisonDateTime = new Date(
        newDeliveryData.livraison_heur_debut
      );
      const finLivraisonDateTime = new Date(newDeliveryData.livraison_heur_fin);

      // Vérification si les dates sont le même jour
      const isSameDay =
        startDateTime.toDateString() === endDateTime.toDateString();

      // Vérification si datetime_start est supérieur ou égal à datetime_end pour le même jour (si applicable)
      if (isSameDay && startDateTime >= endDateTime) {
        throw new Error(
          "L'heure de commande jusqu'à doit être supérieure à l'heure de commande à partir de."
        );
      }

      // Vérification si livraison_heur_debut est supérieur ou égal à livraison_heur_fin pour le même jour (si applicable)
      if (isSameDay && debutLivraisonDateTime >= finLivraisonDateTime) {
        throw new Error(
          "L'heure de fin de livraison estimée doit être supérieure à l'heure de début de livraison estimée."
        );
      }
      const response = await saveDeliveryData(newDeliveryData);
      if (response.status === 201) {
        handleCloseModal();
        history.go(0);
      } else {
        throw new Error(
          `Échec de l'ajout de la livraison. Statut : ${response.status}`
        );
      }
    } catch (error) {
      setError(error.message);
      console.error("Erreur lors de l'ajout de la livraison :", error);
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour fermer la modale
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDelivery(null);
    setNewDeliveryData({
      datetime_start: "",
      datetime_end: "",
      livraison_heur_debut: "",
      livraison_heur_fin: "",
      zone_id: "",
      status: "",
    });
    setError(null); // Effacer le message d'erreur lorsque la modale est fermée
  };

  // Fonction pour gérer la suppression de livraison
  const handleDeliveryDelete = async (deliveryID) => {
    // Implémentation pour la suppression de la livraison
    try {
      const response = await deleteDeliveryTower(deliveryID);
      if (response.status === 200) {
        handleCloseModal();
        history.go(0);
      } else {
        throw new Error(
          `Échec de la suppression du Tour de livraison. Statut : ${response.status}`
        );
      }
    } catch (error) {
      setError(error.message);
      console.error(
        "Erreur lors de la suppression du Tour de livraison :",
        error
      );
    }
  };

  const handleNewDeliveryChange = (fieldName, value) => {
    let formattedDate;
    formattedDate = value;
    if (value !== "zone" || value !== "status") {
      const formattedDate = moment(value).format("YYYY-MM-DD HH:mm:ss");
    }
    if (modalMode === "add") {
      // For adding a new Tower delivery
      setNewDeliveryData((prevData) => ({
        ...prevData,
        [fieldName]: formattedDate,
      }));
    } else {
      // For updating an existing Tower delivery
      setSelectedDelivery((prevDelivery) => ({
        ...prevDelivery,
        [fieldName]: formattedDate,
      }));
    }
  };

  // Colonnes mémorisées pour le composant DynamicTable
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Heure de commande à partir de",
        accessor: "datetime_start",
        Cell: ({ value }) =>
          format(new Date(value), "dd-MM-yyyy HH:mm", { locale: fr }),
      },
      {
        Header: "Heure de commande jusqu'à",
        accessor: "datetime_end",
        Cell: ({ value }) =>
          format(new Date(value), "dd-MM-yyyy HH:mm", { locale: fr }),
      },
      {
        Header: "Heure de début livraison estimé",
        accessor: "livraison_heur_debut",
        Cell: ({ value }) =>
          format(new Date(value), "dd-MM-yyyy HH:mm", { locale: fr }),
      },
      {
        Header: "Heure de fin de livraison estimé",
        accessor: "livraison_heur_fin",
        Cell: ({ value }) =>
          format(new Date(value), "dd-MM-yyyy HH:mm", { locale: fr }),
      },
      {
        Header: "Etat",
        accessor: "status",
        Cell: ({ value }) => (
          <Badge className={value === "actif" ? "bg-success" : "bg-danger"}>
            {value}
          </Badge>
        ),
      },
      {
        Header: "Date de création",
        accessor: "created_at",
        Cell: ({ value }) =>
          format(new Date(value), "dd-MM-yyyy", { locale: fr }),
      },
      {
        Header: "Actions",
        accessor: "original.id",
        Cell: ({ value, row }) => (
          <div>
            {/* Icône d'édition avec un gestionnaire de clic */}
            {/* <a className="p-2" onClick={() => handleModalOptionClick(row)}>
              <img src={IconEdit} width="20px" alt="Supprimée" />
            </a> */}
            <a
              href="#"
              className="p-2"
              onClick={() => handleDeliveryDelete(row.original.id)}
            >
              <img src={IconTrash} width="20px" alt="Supprimer" />
            </a>
          </div>
        ),
      },
    ],
    []
  );

  // Rendu du message de chargement pendant que les données sont récupérées
  if (!deliveryData || !deliveryData.data) {
    return (
      <Container>
        <p>Chargement des données de livraison...</p>
      </Container>
    );
  }

  // Rendu du composant
  return (
    <Container>
      <div className="DeliveryList">
        {/* Navigation par breadcrumb */}
        <p className="h6">Accueil / Tours de Livraisons</p>

        {/* En-tête et filtres */}
        <Row className="pt-4 pb-2">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <ClickBack />
            {/* Afficher le titre dynamique */}
            <h1 className="p-1">Tours de Livraisons</h1>
          </Col>
          <Col
            xs
            lg="3"
            className="d-flex justify-content-end align-items-center"
            style={{ alignContent: "right" }}
          >
            <Button variant="primary" onClick={handleOpenAddDeliveryModal}>
              Ajouter un Tour de livraison
            </Button>
          </Col>
        </Row>
         <SearchBar searchTerm={searchTerm} handleSearch={handleSearch} name="tourLivraison" />
        {/* Rendu du composant DynamicTable */}
        {loading ? (
          // Affichage du chargeur pendant que les données sont récupérées
          <p className="lead">Chargement...</p>
        ) : (
          <DynamicTable
            columns={columns}
            data={deliveryData?.data}
            link={deliveryData?.links}
            last_page={deliveryData?.last_page}
            setNbr={setNbr}
            nbr={nbr}
            total={deliveryData?.total}
            name="deliveryTower"
          />
        )}
      </div>

      {/* Modale Bootstrap */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalMode === "add"
              ? "Ajouter un nouveau Tour de livraison"
              : "Modifier/Archiver Tour de livraison : " +
                (selectedDeliveryID ? selectedDeliveryID.original.id : "")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Form.Group controlId="formDeliveryName" className="mb-2">
              <Row>
                <Col md={6}>
                  <Form.Label>Heure de commande à partir de : </Form.Label>
                  <input
                    type="datetime-local" // Définition du type datetime-local
                    id="datetime_start"
                    name="datetime_start"
                    className="form-control"
                    value={
                      modalMode === "edit"
                        ? selectedDelivery?.datetime_start
                        : newDeliveryData.datetime_start
                    }
                    onChange={(e) =>
                      handleNewDeliveryChange("datetime_start", e.target.value)
                    } // Gestionnaire d'événements pour mettre à jour la valeur
                  />
                </Col>
                <Col md={6}>
                  <Form.Label>Heure de commande jusqu'à :</Form.Label>
                  <input
                    type="datetime-local" // Définition du type datetime-local
                    id="datetime_end"
                    name="datetime_end"
                    className="form-control"
                    value={
                      modalMode === "edit"
                        ? selectedDelivery?.datetime_end
                        : newDeliveryData.datetime_end
                    }
                    onChange={(e) =>
                      handleNewDeliveryChange("datetime_end", e.target.value)
                    } // Gestionnaire d'événements pour mettre à jour la valeur
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Label>Heure de début livraison estimé :</Form.Label>
                  <input
                    type="datetime-local" // Définition du type datetime-local
                    id="livraison_heur_debut"
                    name="livraison_heur_debut"
                    className="form-control"
                    value={
                      modalMode === "edit"
                        ? selectedDelivery?.livraison_heur_debut
                        : newDeliveryData.livraison_heur_debut
                    }
                    onChange={(e) =>
                      handleNewDeliveryChange(
                        "livraison_heur_debut",
                        e.target.value
                      )
                    } // Gestionnaire d'événements pour mettre à jour la valeur
                  />
                </Col>
                <Col md={6}>
                  <Form.Label>Heure de fin de livraison estimé :</Form.Label>
                  <input
                    type="datetime-local" // Définition du type datetime-local
                    id="livraison_heur_fin"
                    name="livraison_heur_fin"
                    className="form-control"
                    value={
                      modalMode === "edit"
                        ? selectedDelivery?.livraison_heur_fin
                        : newDeliveryData.livraison_heur_fin
                    }
                    onChange={(e) =>
                      handleNewDeliveryChange(
                        "livraison_heur_fin",
                        e.target.value
                      )
                    } // Gestionnaire d'événements pour mettre à jour la valeur
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group controlId="formDeliveryzone" className="mb-2">
              <Form.Label>zone :</Form.Label>
              <Form.Control
                as="select"
                name="zone"
                value={
                  modalMode === "edit"
                    ? selectedDelivery?.zone_id
                    : newDeliveryData.zone_id
                }
                onChange={(e) =>
                  handleNewDeliveryChange("zone_id", e.target.value)
                }
                required
              >
                <option value="" disabled>
                  Sélectionner la zone
                </option>
                {zoneData?.map((zone) => (
                  <option value={zone.id} key={zone.id}>
                    {zone.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {modalMode === "add" && ( //le champs status apparaitre dans le foem seulement pour l'ajout
              <Form.Group controlId="formDeliveryStatus" className="mb-2">
                <Form.Label>Statut</Form.Label>
                <Form.Control
                  as="select"
                  name="status"
                  value={newDeliveryData.status}
                  onChange={(e) =>
                    handleNewDeliveryChange("status", e.target.value)
                  }
                  required
                >
                  <option value="" disabled>
                    Sélectionner le statut
                  </option>
                  <option value="actif">Actif</option>
                  <option value="inactif">Inactif</option>
                </Form.Control>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fermer
          </Button>
          {modalMode === "edit" && (
            <Button
              variant="danger"
              onClick={() =>
                handleDeliveryDelete(
                  selectedDeliveryID ? selectedDeliveryID.original.id : ""
                )
              }
            >
              Archiver
            </Button>
          )}
          <Button
            variant="primary"
            onClick={
              modalMode === "add" ? handleAddDelivery : handleUpdateDelivery
            }
          >
            {modalMode === "add" ? "Ajouter" : "Modifier"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

// Exportation du composant par défaut
export default ListScreen;
