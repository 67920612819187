import React, { useMemo, useState, forwardRef, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Badge,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import IconShow from "../../../images/fi_eye.png";
import {
  convertOrdersToInvoice,
  fetchAllBySupplier,
  updateStatusCommande,
} from "./DataService";
import { useParams } from "react-router-dom";
import DynamicTable from "../../../components/DynamicTable";
import ElementsPerPageDropdown from "../Component/ElementsPerPageDropdown";
registerLocale("fr", fr);

// Contenue par défaut de la section Date
const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <button className="form-control" onClick={onClick} ref={ref}>
    {value || "Sélectionner une période"}
  </button>
));

function ListCommandesFournisseur(props) {
  const [searchTerm, setSearchTerm] = useState(""); //Initialisation du terme de la recherche
  const [startDate, setStartDate] = useState(null); //Initialisation de la Data de début
  const [endDate, setEndDate] = useState(null); //Initialisation de la Date Fin
  const [isCalendarOpen, setIsCalendarOpen] = useState(false); //Initialisation de l'etat de la Calendrier
  const [selectedRows, setSelectedRows] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [data, setData] = useState();
  const [filtreDate, setFiltreDate] = useState("");
  const [nbr, setNbr] = useState(1);
  const [nbElement, setNbElement] = useState(10);

  const { idFournisseur } = useParams();
  //Cette Fonction rendre les date choisit dans le Calendrier dans l'input
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const getOrdersBySupplier = async () => {
    try {
      const response = await fetchAllBySupplier(
        "fo/ListeCommandeFournisseur",
        idFournisseur,
        nbr,
        filtreDate,
        searchTerm,
        nbElement
      );
      setData(response);
    } catch (error) {
      console.error("There was an error fetching  fournisseurs!", error);
    }
  };
  //Appel à API qui permet de convertir la commande en facture
  const handelConvertir = async () => {
    const formattedKeys = Object.keys(selectedRows).join(",");
    const res = await convertOrdersToInvoice(formattedKeys);
    if (res) {
      props.setEventKeyValue("factures");
      setSelectedRows({});
      setSelectAll(false);
      setIsDisabled(true); // Disable the button after deselecting
    }
  };

  //Cette Fonction marche lors du click sur Appliquer pour filter les donner de tableau selon choix date
  const handleApply = () => {
    const formattedStartDate = startDate ? format(startDate, "dd-MM-yyyy") : "";
    const formattedEndDate = endDate ? format(endDate, "dd-MM-yyyy") : formattedStartDate ;
    setFiltreDate(
      `start_date=${formattedStartDate}&end_date=${formattedEndDate}`
    );
    setIsCalendarOpen(false); // Fermer Calendrier
  };

  //Cette Fonction marche lors du click sur Annuler rénitialiser tous les valeurs à 0
  const handleCancel = () => {
    setStartDate(null);
    setEndDate(null);
    setFiltreDate("");
    setIsCalendarOpen(false); // Fermer Calendrier
    getOrdersBySupplier();
  };
  const handleCheckboxChange = (rowId) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = {
        ...prevSelectedRows,
        [rowId]: !prevSelectedRows[rowId], // Toggle selection for the row
      };     
      // Si au moins une ligne est sélectionnée, activer le bouton
      const hasSelectedRows = Object.values(newSelectedRows).some((isSelected) => isSelected);
      setIsDisabled(!hasSelectedRows); // Désactiver si aucune ligne sélectionnée
      return newSelectedRows;
    });
  };
  
  const handleSelectAllChange = () => {
    if (selectAll) {
      // Unselect all rows
      setSelectedRows({});
      setIsDisabled(true); // Disable button since no rows are selected
    } else {
      if (Array.isArray(data?.data)) {
        // Select all rows, skipping those where StatuPaiement == 1
        const allRows = data.data.reduce((acc, item) => {
          if (item.StatuPaiement !== 1) {
            acc[item.id] = true;
          }
          return acc;
        }, {});
        setSelectedRows(allRows);
        setIsDisabled(false); // Enable the button since rows are selected
      } else {
        console.error("Data is not an array");
      }
    }
    setSelectAll(!selectAll);
  };
  

  /**
 * 
 *-  Statut de paiement (Facturé ou non facturé via liste déroulante)
-  Statut de commande via liste déroulante (En cours de préparation / En cours de livraison / Livrée / Annulée)
 */
  const mapStatusPayment = (statusID) => {
    switch (statusID) {
      case 1:
        return "Facturé";
      case 2:
        return "Non facturé";
      case 3:
        return "En retard";
      default:
        return "";
    }
  };
  const isRowSelected = (rowId) => selectedRows[rowId] || false;
  const handlePaymentStatusChange = async (newStatus, orderId) => {
    try {
      // Example API call to update payment status
      const response = await updateStatusCommande(
        "fo/updateCommandeFournisseur",
        orderId,
        newStatus
      );
      // Optionally, refresh the data or update the state
      getOrdersBySupplier();
    } catch (error) {
      console.error("Error updating payment status:", error);
      // Handle error (e.g., show a notification)
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <input
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAllChange}
          />
        ),
        id: "selectAll",
        Cell: ({ row }) => (
          <input
            type="checkbox"
            onChange={() => handleCheckboxChange(row.original.id)}
            checked={isRowSelected(row.original.id)}
            disabled={row.original.StatuPaiement == 1}          
          />
        ),
      },
      {
        Header: "Date de la commande",
        accessor: "DateCommande",
        Cell: ({ value }) => (
          <span>
            {value ? format(new Date(value), "dd-MM-yyyy HH:mm") : ""}
          </span>
        ),
      },
      { Header: "Raison Social", accessor: "fournisseur_profil.user_profil.RaisonSocial" },
      { Header: "Adresse de livraison", accessor: "AdresseLivraison" },
      {
        Header: "Date de livraison souhaitée",
        accessor: "DateLivraison",
        Cell: ({ value }) => (
          <span>
            {value ? format(new Date(value), "dd-MM-yyyy HH:mm") : ""}
          </span>
        ),
      },

      {
        Header: "N° de téléphone client",
        accessor: "TelFournisseur",
      },
      {
        Header: "Adresse mail fournisseur",
        accessor: "emailFournisseur",
      },
      {
        Header: "Statut de paiement",
        accessor: "StatuPaiement",
        Cell: ({ row }) => (
          <Form.Group className="mb-3" style={{ minWidth: "120px" }}>
            <Form.Select
              className="form-control w-100"
              value={row.original.StatuPaiement}
              onChange={(e) =>
                handlePaymentStatusChange(e.target.value, row.original.id)
              }
            >
              <option value="1">Facturé</option>
              <option value="2">Non facturé</option>
            </Form.Select>
          </Form.Group>
        ),
      },
      {
        Header: "Statut de commande",
        accessor: "StatuCommande",
        Cell: ({ value }) => {
          let statusLabel = "";
          let badgeClass = "bg-secondary"; // Default badge color

          switch (value) {
            case 1:
              statusLabel = "En cours de livraison";
              badgeClass = "bg-warning";
              break;
            case 2:
              statusLabel = "Livré";
              badgeClass = "bg-success";
              break;
            case 3:
              statusLabel = "En cours de préparation";
              badgeClass = "bg-info";
              break;
            case 6:
              statusLabel = "Annulé";
              badgeClass = "bg-danger";
              break;
            default:
              statusLabel = "En cours de préparation";
              badgeClass = "bg-info";
          }

          return <Badge className={badgeClass}>{statusLabel}</Badge>;
        },
      },
      { Header: "Bordereau de livraison", accessor: "NBonLivraison" },
      {
        Header: "Actions",
        Cell: ({ value, row }) => (
          <div>
            <a
              href={`/commande-fournisseur/show/${row.original.id}`}
              className="p-2"
            >
              <img src={IconShow} width="20px" alt="Voir" />
            </a>
          </div>
        ),
      },
    ],
    [handleCheckboxChange, isRowSelected, selectAll, isDisabled]
  );

  
  const handleNombreElementParPage = (nbElement) => {
    setNbElement(nbElement); // Update the state with the selected value
  };
  const numberOfSelectedRows = Object.values(selectedRows).filter(value => value === true).length
  useEffect(() => {
    getOrdersBySupplier();
  }, [filtreDate, nbElement,selectAll,selectedRows,nbr]);
  return (
    <Container>
      <Row className="pt-4 pb-2">
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="col-3"
        >
                <ElementsPerPageDropdown
              nbElement={nbElement}
              onNbElementChange={handleNombreElementParPage}
            />
        </div>
        <Col lg="6" className="d-flex  col-lg-7">
          <DatePicker
            selected={startDate}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            dateFormat="dd/MM/yyyy"
            maxDate={new Date()}
            monthsShown={2}
            locale="fr"
            customInput={<CustomInput />}
            open={isCalendarOpen}
            onClickOutside={() => setIsCalendarOpen(false)} // Close calendar on outside click
            onInputClick={() => setIsCalendarOpen(true)} // Open calendar on input click
            popperPlacement="bottom" // Ensure the calendar opens below the input
            calendarContainer={({ children }) => (
              <div className="react-datepicker">
                {children}
                <div className="react-datepicker__footer">
                  <button
                    className="btn btn-secondary margin-date"
                    onClick={handleCancel}
                  >
                    Annuler
                  </button>
                  <button className="btn btn-primary" onClick={handleApply}>
                    Appliquer
                  </button>
                </div>
              </div>
            )}
          />
        </Col>
      </Row>

      <h4>Nombre de ligne selectionneés: {numberOfSelectedRows}</h4>
      {data && (
        <DynamicTable
        columns={columns}
        data={data?.data}
        link={data?.links}
        last_page={data?.last_page}
        setNbr={setNbr}
        nbr={nbr}
        total={data?.total}
        name="commandes_fournisseurs"
        handleCheckboxChange={handleCheckboxChange} // Pass as prop
      />
        )} 
      <Row>
        <Col className="mt-3 d-flex justify-content-end align-items-center col-11">
          <Button
            variant="primary"
            disabled={isDisabled}
            type="submit"
            onClick={handelConvertir}
          >
            Transformer en facture
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default ListCommandesFournisseur;
