
// export default PlacesAutocompleteComponent;
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const PlacesAutocompleteComponent = ({
  name,
  value,
  setFieldValue,
  setFieldTouched,
  searchOptions,
  className,
  handleFieldBlur,
  setFieldError,
}) => {
  const [selectedFromSuggestions, setSelectedFromSuggestions] = useState(false);
 
  const handleAddressChange = (addr) => {
    setFieldValue(name, addr); // Met à jour la valeur dans Formik
    setFieldTouched(name, true); // Marque le champ comme touché immédiatement
    if (!selectedFromSuggestions) {
      setFieldError(
        name,
        "Vous devez sélectionner une adresse depuis les suggestions !"
      );
    }
       if (name === "pickupAdr") {
      setFieldValue("altitude_Fournisseur", "");
      setFieldValue("longitude_Fournisseur", "");
      setFieldTouched("altitude_Fournisseur", false);
      setFieldTouched("longitude_Fournisseur", false);
    }
    else if (name === "address") {
      setFieldValue("altitude_Livreur", "");
      setFieldValue("longitude_Livreur", "");
      setFieldTouched("altitude_Livreur", false);
      setFieldTouched("longitude_Livreur", false);
    }
 
  };

  const customHandleBlur = () => {
    if (!selectedFromSuggestions) {
      setFieldError(
        name,
        "Vous devez sélectionner une adresse depuis les suggestions !"
      );
      setFieldValue(name, value); // Garde la valeur mais invalide
    }
    setFieldTouched(name, true);
    if (handleFieldBlur) handleFieldBlur(); // Appelle le handleFieldBlur parent si fourni
  };

  const handleAddressSelect = async (address,event) => {
    try {
      const results = await geocodeByAddress(address);
      if (results.length > 0) {
        const { lat, lng } = await getLatLng(results[0]);
        setSelectedFromSuggestions(true); 
        setFieldValue(name, address);
        setFieldTouched(name, true);
        setSelectedFromSuggestions(true); // Valide la sélection
        setFieldError(name, ""); // Efface l'erreur
        // Stocker les coordonnées selon le champ
        if (name === "pickupAdr") {
          setFieldValue("altitude_Fournisseur", lat);
          setFieldValue("longitude_Fournisseur", lng);
          setFieldTouched("altitude_Fournisseur", true);
          setFieldTouched("longitude_Fournisseur", true);
          setFieldError(name, ""); // Efface l'erreur
        } else if (name === "address") {
          setFieldValue("altitude_Livreur", lat);
          setFieldValue("longitude_Livreur", lng);
          setFieldTouched("altitude_Livreur", true);
          setFieldTouched("longitude_Livreur", true);
          setFieldError(name, ""); // Efface l'erreur
        }
        setFieldError(name, ""); // Efface l'erreur
      } 
      
    
    } catch (error) {

      console.error("Erreur lors de la sélection d'adresse:", error);
      setFieldError(name, "Vous devez sélectionner une adresse depuis les suggestions !");
    }
  };

  return (
    <Col>
      <PlacesAutocomplete
        value={value}
        onChange={handleAddressChange}
        onSelect={(address, event) => handleAddressSelect(address, event)}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="form-floating">
            <input
              {...getInputProps({
                placeholder: `${name}`,
                className:
                  className === "backWhite"
                    ? "form-control backWhite"
                    : "form-control mb-3",
                onBlur: customHandleBlur, // Utilise notre gestionnaire personnalisé          
                  // Empêche toujours la soumission du formulaire

                  // if (event.key === "Enter") {
                  //   setFieldError(name, "Vous devez sélectionner une adresse depuis les suggestions !");
                  //   event.preventDefault();
                    

                  //   // Logique de réinitialisation
                  //   setSelectedFromSuggestions(false);
                  //   //setFieldValue(name, "");
                  //   setFieldTouched(name, true);

                  //   // Réinitialisation conditionnelle selon le champ
                  //   if (name === "pickupAdr") {
                  //     setFieldValue("altitude_Fournisseur", "");
                  //     setFieldValue("longitude_Fournisseur", "");
                  //     setFieldTouched("altitude_Fournisseur", false);
                  //     setFieldTouched("longitude_Fournisseur", false);
                  //     setFieldError(name, "Vous devez sélectionner une adresse depuis les suggestions !");
                  //   }
                  //   else if (name === "address") {
                  //     setFieldValue("altitude_Livreur", "");
                  //     setFieldValue("longitude_Livreur", "");
                  //     setFieldTouched("altitude_Livreur", false);
                  //     setFieldTouched("longitude_Livreur", false);
                  //     setFieldError(name, "Vous devez sélectionner une adresse depuis les suggestions !");
                  //   }

                  //   // Debugging - utilisez les valeurs directement depuis les props
                  //   console.log("Valeurs réinitialisées :", {
                  //     [name]: value,

                  //     altitude: name === "pickupAdr" ? "" : "",
                  //     longitude: name === "address" ? "" : ""
                  //   });
                   
                  // }
                // },
                //onKeyDown: handleAddressChange,  // <-- Ajout pour gérer "Enter"
                onKeyDown: (event) => {
                  // Vérifier si c'est la touche "Enter"
                  if (event.key === "Enter") { 
                    event.preventDefault(); // Empêcher la soumission
                    setFieldError(name, "Vous devez sélectionner une adresse depuis les suggestions !");
                  }
                },
             
              })}
            />
            <label htmlFor="floatingAdr" className="colorInput">
              {name}

            </label>

            <div
              className={`container-autocomplete ${suggestions.length > 0 ? "d-block" : "d-none"
                }`}
            >
              {loading && <div>Chargement...</div>}
              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? "#41B6E6" : "#fff",
                  cursor: "pointer",
                };
                return (
                  <div
                    className="mapItem"
                    {...getSuggestionItemProps(suggestion, { style })}
                    key={suggestion.placeId}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </Col>
  );
};

export default PlacesAutocompleteComponent;