// Importation des dépendances et composants nécessaires
import React, { useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Row, Col, Modal, Form, Button, Alert, Badge } from 'react-bootstrap';
import ClickBack from '../../components/buttons/ClickBack';
import DynamicTable from '../../components/DynamicTable';
import moment from 'moment';
import { fetchDeliveriesData, updateDeliveryStatus, updateDeliveryData, saveDeliveryData } from './DataService';
import IconEdit from '../../images/edit.png';
import IconTrash from "../../images/trash.png";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import IconShow from "../../images/fi_eye.png"
import SearchBar from '../../components/SearchBar';

// Définition du composant fonctionnel
function ListScreen(props) {
  // Initialisation des hooks nécessaires
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState('add'); // Ajouter un état pour suivre le mode de la modale
  const [deliveryData, setDeliveryData] = useState(null);
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [selectedDeliveryID, setSelectedDeliveryID] = useState(null);
  const [loading, setLoading] = useState(false);
  // Mise à jour de l'état pour gérer le téléchargement de fichiers
  const [kmlFile, setKmlFile] = useState(null); // État pour le fichier KML
  const [kmlFileName, setKmlFileName] = useState(''); // État pour le nom du fichier KML
  const [nbr, setNbr] = useState(1); //Etat de nombre de page pour la pagination
  const [error, setError] = useState(null); // État pour le message d'erreur
  const [searchTerm ,setSearchTerm] =useState('');
  const [newDeliveryData, setNewDeliveryData] = useState({
    name: '',
    status: '',
    path: ''
  }); // État pour les nouvelles données de livraison

  // Fonction pour gérer le téléchargement de fichiers KML
  const handleKmlFileUpload = (event) => {
    const file = event.target.files[0];
    setKmlFile(file);
    setKmlFileName(file.name);
  };

  // Fonction pour gérer les changements de saisie pour les données de livraison nouvelles ou sélectionnées
  const handleNewDeliveryChange = (event) => {
    const { name, value } = event.target;
    if (modalMode === 'add') {
      // Pour ajouter une nouvelle livraison
      setNewDeliveryData(prevData => ({
        ...prevData,
        [name]: value
      }));
    } else {
      // Pour modifier une livraison existante
      setSelectedDelivery(prevDelivery => ({
        ...prevDelivery,
        [name]: value
      }));
    }
  };

  // Fonction pour gérer le clic sur l'option de la modale
  const handleModalOptionClick = (deliveryID) => {
    // Mettre à jour l'identifiant de livraison sélectionné
    setSelectedDeliveryID(deliveryID);

    // S'assurer que l'ID de livraison et ses propriétés sont valides avant de continuer
    if (deliveryID && deliveryID.original) {
      // Enregistrer le nom de la livraison sélectionnée pour le débogage

      // Définir selectedDelivery avec les données de la livraison sélectionnée
      setSelectedDelivery(deliveryID.original);
    } else {
      // Si l'ID de livraison ou ses propriétés ne sont pas valides, réinitialiser selectedDelivery à null ou à un objet vide
      setSelectedDelivery(null); // ou setSelectedDelivery({})
    }

    // Puis procéder à la définition de showModal et modalMode
    setShowModal(true);
    setModalMode('edit'); // Définir le mode de la modale sur modification
  };

  // Fonction pour ouvrir la modale d'ajout de livraison
  const handleOpenAddDeliveryModal = () => {
    setShowModal(true);
    setModalMode('add'); // Définir le mode de la modale sur ajout
  };

  // Fonction pour mettre à jour les données de livraison
  const handleUpdateDelivery = async () => {
    // Implémentation pour la mise à jour des données de livraison
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('name', selectedDelivery.name);
      formData.append('status', selectedDelivery.status);
      formData.append('file', kmlFile); // Ajouter le fichier KML aux données du formulaire
      formData.append('id', selectedDeliveryID.original.id);
      // Validation des champs obligatoires
      if (!selectedDelivery.name || !selectedDelivery.status || !kmlFile) {
        throw new Error('Tous les champs sont obligatoires.');
      }

      const response = await updateDeliveryData(formData);
      if (response.status === 200) {
        handleCloseModal();
        history.go(0);
      } else {
        throw new Error(`Échec de la mise à jour de la livraison. Statut : ${response.status}`);
      }
    } catch (error) {
      setError(error.message);
      console.error('Erreur lors de la mise à jour de la livraison :', error);
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour gérer la suppression de livraison
  const handleDeliveryDelete = async (deliveryID) => {
    // Implémentation pour la suppression de la livraison
    try {
      const response = await updateDeliveryStatus(deliveryID);
      if (response.status === 200) {
        handleCloseModal();
        history.go(0);
      } else {
        throw new Error(`Échec de la suppression de la livraison. Statut : ${response.status}`);
      }
    } catch (error) {
      setError(error.message);
      console.error('Erreur lors de la suppression de la livraison :', error);
    }
  };

  // Fonction pour ajouter une nouvelle livraison
  const handleAddDelivery = async () => {
    // Implémentation pour l'ajout d'une nouvelle livraison
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('name', newDeliveryData.name);
      formData.append('status', newDeliveryData.status);
      formData.append('file', kmlFile); // Ajouter le fichier KML aux données du formulaire

      // Validation des champs obligatoires
      if (!newDeliveryData.name || !newDeliveryData.status || !kmlFile) {
        throw new Error('Tous les champs sont obligatoires.');
      }

      const response = await saveDeliveryData(formData);
      if (response.status === 201) {
        handleCloseModal();
        history.go(0);
      } else {
        throw new Error(`Échec de l'ajout de la livraison. Statut : ${response.status}`);
      }
    } catch (error) {
      setError(error.message);
      console.error('Erreur lors de l\'ajout de la livraison :', error);
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour fermer la modale
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDelivery(null);
    setNewDeliveryData({
      name: '',
      status: '',
      path: ''
    });
    setError(null); // Effacer le message d'erreur lorsque la modale est fermée
  };

  // Fonction pour récupérer les données de livraison
  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await fetchDeliveriesData(nbr,searchTerm);
      setDeliveryData(data);
    } catch (error) {
      setError('Erreur lors de la récupération des données de livraison.');
      console.error('Erreur lors de la récupération des données de livraison :', error);
    } finally {
      setLoading(false);
    }
  };

  const extractFileName = (path) => {
    const match = path.match(/(\d+)\.kml$/);
    return match ? match[1] : 'Nom de fichier non trouvé';
  };
  
    // Update search term and fetch data
  const handleSearch = (term) => {
    setSearchTerm(term);
    setNbr(1); // Reset to first page
   // fetchData(); // Trigger fetch with updated search term
  };
  // useEffect pour charger automatiquement les données lorsque le composant est monté
    useEffect(() => {
      fetchData();
    }, [nbr, searchTerm]);

  // Colonnes mémorisées pour le composant DynamicTable
  const columns = useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Nom', accessor: 'name' },
      {
        Header: 'Nom fichier KML',
        accessor: 'path',
        Cell: ({ value }) => extractFileName(value), // Utilisez la fonction pour extraire et afficher le nom du fichier
      },
      { Header: 'Etat', accessor: 'status' ,
        Cell: ({ value }) => (
          <Badge className={value === "active" ? "bg-success" : "bg-danger"}>
            {value}
          </Badge>
        ),
      },
      { Header: 'Date de création', accessor: 'created_at',
        Cell: ({ value }) =>
          format(new Date(value), "dd-MM-yyyy HH:mm", { locale: fr }),
       },
      {
        Header: 'Actions',
        accessor: 'original.id',
        Cell: ({ value, row }) => (
          <div>
          {/* Show icon with a click handler*/}          
            <a href={`zone/show/${row.original.id}`} className="p-2">
              <img src={IconShow} width="20px" alt="Modifier" />
            </a>
            {/* Icône d'édition avec un gestionnaire de clic */}
            <a className='p-2' onClick={() => handleModalOptionClick(row)}>
              <img src={IconEdit} width='20px' alt='Éditer' />
            </a>
            <a
              href="#"
              className="p-2"
              onClick={() => handleDeliveryDelete(row.original.id)}
            >
              <img src={IconTrash} width="20px" alt="Supprimer" />
            </a>
          </div>
        ),
      },
    ],
    []
  );

  // Rendu du message de chargement pendant que les données sont récupérées
  if (!deliveryData) {
    return <Container><p>Chargement...</p></Container>;
  }

  // Rendu du composant
  return (
    <Container>
      <div className='DeliveryList'>
        {/* Navigation par breadcrumb */}
        <p className='h6'>Accueil / Centres de livraison</p>

        {/* En-tête et filtres */}
        <Row className='pt-4 pb-2'>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <ClickBack />
            {/* Afficher le titre dynamique */}
            <h1 className='p-1'>Centres de livraison</h1>
          </Col>
          <Col xs lg='2' className='d-flex justify-content-end align-items-center' style={{ alignContent: 'right' }}>
            <Button variant='primary' onClick={handleOpenAddDeliveryModal}>Ajouter une Zone Livraison</Button>
          </Col>
        </Row>
        <SearchBar searchTerm={searchTerm} handleSearch={handleSearch} />
        {/* Rendu du composant DynamicTable */}
        {loading ? (
          // Affichage du chargeur pendant que les données sont récupérées
          <p className='lead'>Chargement...</p>
        ) : (
          <DynamicTable columns={columns}
          setSearchTerm={setSearchTerm}
           data={deliveryData?.data}
           link={deliveryData?.links}
           next_page_url={deliveryData?.next_page_url}
           prev_page_url={deliveryData?.prev_page_url}
           last_page={deliveryData?.last_page}
           setNbr={setNbr}
           nbr={nbr}
           total={deliveryData.total}
           name="zoneLivraison"
            />
        )}
      </div>

      {/* Modale Bootstrap */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalMode === 'add' ? 'Ajouter un nouveau centre de livraison' : 'Modifier/Supprimer la livraison : ' + (selectedDeliveryID ? selectedDeliveryID.original.id : '')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Form.Group controlId='formDeliveryName' className='mb-2'>
              <Form.Label>Nom de la zone de livraison</Form.Label>
              <Form.Control
                type='text'
                placeholder='Entrez le nom de la zone de livraison'
                name='name'
                value={modalMode === 'edit' ? selectedDelivery?.name : newDeliveryData.name}
                onChange={handleNewDeliveryChange}
                required
              />
            </Form.Group>
            <Form.Group controlId='formDeliveryStatus' className='mb-2'>
              <Form.Label>Statut</Form.Label>
              <Form.Control
                as='select'
                name='status'
                value={modalMode === 'edit' ? selectedDelivery?.status : newDeliveryData.status}
                onChange={handleNewDeliveryChange}
                required
              >
                <option value='' disabled>Sélectionner le statut</option>
                <option value='active'>Actif</option>
                <option value='inactive'>Inactif</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId='formDeliveryKmlFile' className='mb-2'>
              <Form.Label>Fichier KML</Form.Label>
              <Form.Control
                type='file'
                accept='.kml'
                onChange={handleKmlFileUpload}
                required
              />
              {kmlFileName && <p>Fichier KML sélectionné : {kmlFileName}</p>}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseModal}>Fermer</Button>
          {modalMode === 'edit' && (
            <Button variant='danger' onClick={() => handleDeliveryDelete(selectedDeliveryID ? selectedDeliveryID.original.id : '')}>
              Supprimer
            </Button>
          )}
          <Button variant='primary' onClick={modalMode === 'add' ? handleAddDelivery : handleUpdateDelivery}>
            {modalMode === 'add' ? 'Ajouter' : 'Enregistrer les modifications'}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

// Exportation du composant par défaut
export default ListScreen;
