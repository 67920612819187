//DataService.js
import api from "../../utils/ApiConfig";
// Fetch all Orders from the database
const fetchOrdersData = async (nbr,termSearch) => {
  try {
    const response = await api.get(`/commandes/all?page=${nbr}&search_term=${termSearch}`);
    return response.data;
  } catch (error) {
    console.log("Error fetching data:", error);
    throw error;
  }
};
//Get order by ID 
const fetchOneOrder = async (orderId) =>{
  try{
   const response = await api.get(`/commandes/get/${orderId}`)
    //check if response status is 200 (OK)
    if (response.status === 200) {
    return response;
    } else {
      throw new Error(
        `Failed to get Order data . Status: ${response.status}`
      );
        }
  }catch (error) {
    console.error("Error getting Order data:", error);
    throw error;
  }
}
// Update Order Data
const updateOrder = async (orderId, orderData) => {
  try {
    const response = await api.put(
      `/commandes/${orderId}`,
      orderData
    );
    //check if response status is 200 (OK)
    if (response.status === 200) {
      return response;
    } else {
      throw new Error(
        `Failed to update Order data . Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error updating Order data:", error);
    throw error;
  }
};

//Delete Order
const deleteOrder = async (orderId) => {
  try {
    const response = await api.post(`/commandes/annuler/${orderId}`);
    //check if the response status is 200(OK)
    if (response.status === 200) {
      return response;
    } else {
      throw new Error(`Failed to update Order status:`);
    }
  } catch (error) {
    console.error("Error updating Order status", error);
    throw error;
  }
};

// Export methods ************************************************************
export { fetchOrdersData, updateOrder, deleteOrder, fetchOneOrder };
