import React, { useEffect, useMemo, useState } from "react";
import {
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
} from "react-bootstrap";
import { fetchAllBySupplier, updateStatusCommande } from "./DataService";
import { useParams } from "react-router-dom";
import Download from "../../../images/download.svg";
import { urlPdf } from "../../../utils/ApiConfig";
import DynamicTable from "../../../components/DynamicTable";
import SearchBar from "../../../components/SearchBar";
import ElementsPerPageDropdown from "../Component/ElementsPerPageDropdown";

function FcturesFournisseur({ eventKeyValue }) {
  const [searchTerm, setSearchTerm] = useState(""); //Initialisation du terme de la recherche
  const [data, setData] = useState([]); //Initialisation du terme de la recherche
  const [filtreDate, setFiltreDate] = useState("");
  const [nbr, setNbr] = useState(1);
  const { idFournisseur } = useParams();
  const [nbElement, setNbElement] = useState(10);

  const getOrdersBySupplier = async () => {
    try {
      const response = await fetchAllBySupplier(
        "bo/getFactureByFrounisseur",
        idFournisseur,
        nbr,
        filtreDate,
        searchTerm,
        nbElement
      );
      console.log("API Response:", response);
      setData(response);
    } catch (error) {
      console.error("There was an error fetching  fournisseurs!", error);
    }
  };
  const handleStatusChange = async (newStatus, invoice) => {
    try {
      // Example API call to update payment status
      const response = await updateStatusCommande(
        "bo/changeStatuFacture",
        invoice,
        newStatus
      );
      // Optionally, refresh the data or update the state
      getOrdersBySupplier();
    } catch (error) {
      console.error("Error updating payment status:", error);
      // Handle error (e.g., show a notification)
    }
  };

  const handleNombreElementParPage = (nbElement) => {
    setNbElement(nbElement); // Update the state with the selected value
  };
  // Update search term and fetch data
  const handleSearch = (term) => {
    setSearchTerm(term);
    setNbr(1); // Reset to first page
  };
  useEffect(() => {
    getOrdersBySupplier();
  }, [searchTerm, nbElement, eventKeyValue, nbr]);

  const columns = useMemo(
    () => [
      { Header: "Raison Social", accessor: "RaisonSocial" },
      { Header: "Date de facturation", accessor: "dateFacturation" },
      {
        Header: "Statut",
        accessor: "statuFacture",
        Cell: ({ row }) => (
          <Form.Group className="mb-3" style={{ minWidth: "120px" }}>
            <Form.Select
              className="form-control w-100"
              value={row.original.statuFacture}
              // className="form-select"
              onChange={(e) =>
                handleStatusChange(e.target.value, row.original.idFacture)
              }
            >
              <option value="1">Payée</option>
              <option value="2">Non payée</option>
              <option value="3">En retard</option>
            </Form.Select>
          </Form.Group>
        ),
      },
      { Header: "Adresse du fournisseur", accessor: "adresseFournisseur" },
      { Header: "E-mail fournisseur", accessor: "emailFournisseur" },
      {
        Header: "Actions",
        accessor: "idFacture",
        Cell: ({ value, row }) => (
          <div>
            <a
              target="_blank"
              href={`${urlPdf}/api/getFactureFournisseur/${row.original.idFacture}`}
              className="p-2"
            >
              <img src={Download} width="20px" alt="Download" />
            </a>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Container>
      {/* Header and filters */}
      <Row className="pt-4 pb-2 align-items-center">
      <ElementsPerPageDropdown
              nbElement={nbElement}
              onNbElementChange={handleNombreElementParPage}
            />
        <Col
          xs
          lg="2"
          className="d-flex justify-content-end align-items-center"
          style={{ alignContent: "right" }}
        ></Col>
      </Row>
      <SearchBar searchTerm={searchTerm} handleSearch={handleSearch} />
      {data && (
        <DynamicTable
          columns={columns}
          data={data?.data|| []}
          link={data?.links}
          last_page={data?.last_page}
          setNbr={setNbr}
          nbr={nbr}
          total={data?.total}
          name="factures_fournisseurs"
        />
      )}
    </Container>
  );
}

export default FcturesFournisseur;
