// dataService.js
import api from '../../utils/ApiConfig';

// Fetch all deliveries from the database
const fetchDeliveriesData = async (nbr, termSearch) => {
  try {
    // Créez un objet avec les paramètres
    const params = {
      page: nbr,
      search_term: termSearch, 
    };
    // Filtrer et encoder les paramètres pour éviter les erreurs
    const filteredParams = Object.entries(params)
      .filter(([_, value]) => value !== undefined && value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    // Construire l'URL finale
    const response = await api.get(`/bo/zones?${filteredParams}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
// Update delivery status
const updateDeliveryStatus = async (deliveryId) => {
  try {
    const response = await api.delete(`/zones/delete/${deliveryId}`);
    
    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Check if the response data is valid and return the updated delivery data
      return response;
    } else {
      throw new Error(`Failed to update delivery status. Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error updating delivery status:', error);
    throw error;
  }
};

// Update delivery data
const updateDeliveryData = async (deliveryData) => {
  try {
    const response = await api.post(`/zones/update`, deliveryData);
    
    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Check if the response data is valid and return the updated delivery data
      return response;
    } else {
      throw new Error(`Failed to update delivery data. Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error updating delivery data:', error);
    throw error;
  }
};

// Save delivery data
const saveDeliveryData = async (deliveryData) => {
  try {
    const response = await api.post(`/zones/create`, deliveryData);
    
    // Check if the response status is 201 (Created)
    if (response.status === 201) {
      // Check if the response data is valid and return the created delivery data
      return response;
    } else {
      throw new Error(`Failed to save delivery data. Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error saving delivery data:', error);
    throw error;
  }
};
//get oneZone by id 
const fetchDeliveryById = async (id) => {
  try {
    const response = await api.get(`/zones-livraison/${id}`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des données de la zone de livraison :', error);
    throw error;
  }
};

// Export methods
export { fetchDeliveriesData, updateDeliveryStatus, updateDeliveryData, saveDeliveryData, fetchDeliveryById };
