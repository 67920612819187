// dataService.js
import api from '../../utils/ApiConfig';

// Fetch all users************************************************************
const fetchUserData = async (role, nbr, termSearch) => {
  try {
    // Créez un objet avec les paramètres
    const params = {
      role,
      page: nbr,
      search_term: termSearch, 
    };

    // Filtrez les paramètres pour ne conserver que ceux qui sont définis et non vides
    const filteredParams = Object.entries(params)
      .filter(([_, value]) => value !== undefined && value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    const response = await api.get(`/users/role/${role}?${filteredParams}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Fetch all users width all roles ************************************************************
const fetchAllUserData = async () => {
  try {
    const response = await api.get('/getallusers');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// Update user status  ************************************************************
const updateUserStatus = async (userId) => {
  try {
    const response = await api.post(`/users/${userId}/update-status/0`);
    
    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Check if the response data is valid and return the updated user data
        return response;
    } else {
      throw new Error(`Failed to update user status. Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error updating user status:', error);
    throw error;
  }
};
// Save user data  ************************************************************
const saveUserData = async (UserId, userData) => {
  try {
    const response = await api.post(`/users/update/${UserId}`, userData);
    
    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Check if the response data is valid and return the updated user data
      return response.data;
    } else {
      throw new Error(`Failed to update user data. Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error updating user data:', error);
    throw error;
  }
};
// Create user   ************************************************************

const createUser = async (user) => {
  try{
    const response = await api.post(`/bo/register`,user);
    
    // Check if the response status is 200 (OK)
    if (response.status === 201) {
      // Check if the response data is valid and return the updated user data
        return response;
    } else {
      throw new Error(`Failed to add user . Status: ${response.status}`);
    }

  }catch(error){
    console.error('Error add user data:', error);
    throw error;
  }
}
// Delete user data  ************************************************************
const deleteUser = async (UserId) => {
  try {
    const response = await api.delete(`/users/delete/${UserId}`);
    
    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Check if the response data is valid and return the delete user 
      return response;
    } else {
      throw new Error(`Failed to delete user . Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error delete user data:', error);
    throw error;
  }
};

 
//Get one user By Id   *****************************************************************
const GetOneUser = async (userId) => {
  try{
    const response = await api.get(`/users/byid/${userId}`);
    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Check if the response data is valid and return the updated user data
        return response.data;
    } else {
      throw new Error(`Failed to get user . Status: ${response.status}`);
    }

  }catch(error){
    console.error('Error add user data:', error);
    throw error;
  }
}

// Export methods ************************************************************
export { fetchUserData , updateUserStatus , saveUserData ,fetchAllUserData,createUser, deleteUser, GetOneUser};