// DataService.js
import api from "../../utils/ApiConfig";
// Fetch all Code Promo from the database
const fetchCodePromoData = async (nbr, termSearch) => {
  try {
    // Créez un objet avec les paramètres
    const params = {
      page: nbr,
      search_term: termSearch, 
    };
    // Filtrer et encoder les paramètres pour éviter les erreurs
    const filteredParams = Object.entries(params)
      .filter(([_, value]) => value !== undefined && value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    // Construire l'URL finale
    const response = await api.get(`/bo/code_promos?${filteredParams}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Update Code Promo data
const updateCodePromoData = async (codepromoId, codepromoData) => {
  try {
    const response = await api.put(
      `/code_promos/${codepromoId}`,
      codepromoData
    );

    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Check if the response data is valid and return the updated Code Promo data
      return response;
    } else {
      throw new Error(
        `Failed to update code promo data. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error updating code promo data:", error);
    throw error;
  }
};

// Create new Code Promo
const saveCodePromoData = async (codepromoData) => {
  try {
    const response = await api.post(`/code_promos/add`, codepromoData);

    // Check if the response status is 201 (Created)
    if (response.status === 201) {
      // Check if the response data is valid and return the created Code Promo data
      return response;
    } else {
      throw new Error(`Failed to save code promo. Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error saving code promo data:", error);
    throw error;
  }
};
// Delete Code Promo
const deleteCodePromo = async (codepromoId) => {
  try {
    const response = await api.post(`/code_promos/annuler/${codepromoId}`);

    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Check if the response data is valid and return the updated Code Promo data
      return response;
    } else {
      throw new Error(
        `Failed to update Code Promo status. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error updating code promo status:", error);
    throw error;
  }
};

// Delete Code Promo
const archiveCodePromo = async (codepromoId) => {
  try {
    const response = await api.post(`/code_promos/annuler/${codepromoId}`);

    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Check if the response data is valid and return the updated Code Promo data
      return response;
    } else {
      throw new Error(
        `Failed to update Code Promo status. Status: ${response.status}`
      );
    }
  } catch (error) {
    console.error("Error updating code promo status:", error);
    throw error;
  }
};

// Export methods ************************************************************
export {
  fetchCodePromoData,
  updateCodePromoData,
  saveCodePromoData,
  deleteCodePromo,
  archiveCodePromo
};
