// DataService.js
import api from '../../utils/ApiConfig';
// Fetch all Delivery Tower from the database
const fetchDeliveryTowerData = async (nbr,termSearch) => {
    try {
      const response = await api.get(`/tour-livraisons?page=${nbr}&search_date=${termSearch}`);
      return response.data    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };
  // Fetch all Zones from the database
const fetchZonesData = async () => {
  try {
    const response = await api.get(`/zones`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
  // Update Tower delivery data
const updateDeliveryData = async (deliveryId, deliveryData) => {
  try {
    const response = await api.put(`/tour-livraisons/update/${deliveryId}`, deliveryData); 
    
    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Check if the response data is valid and return the updated delivery data
      return response;
    } else {
      throw new Error(`Failed to update delivery data. Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error updating delivery data:', error);
    throw error;
  }
};

// Create new Tower delivery ans save data  
const saveDeliveryData = async (deliveryData) => {
  try {
    const response = await api.post(`/tour-livraisons/create`, deliveryData);
    
    // Check if the response status is 201 (Created)
    if (response.status === 201) {
      // Check if the response data is valid and return the created delivery data
      return response;
    } else {
      throw new Error(`Failed to save delivery data. Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error saving delivery data:', error);
    throw error;
  }
};
// Delete Tower Delivery 
const deleteDeliveryTower = async (deliveryId) => {
  try {
    const response = await api.delete(`/tour-livraisons/delete/${deliveryId}`);  
    
    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Check if the response data is valid and return the updated delivery data
      return response;
    } else {
      throw new Error(`Failed to update delivery status. Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error updating delivery status:', error);
    throw error;
  }
};
  // Export methods ************************************************************
export { fetchDeliveryTowerData ,updateDeliveryData,saveDeliveryData,deleteDeliveryTower,fetchZonesData};