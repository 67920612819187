import React, { useEffect, useState, useCallback } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import CategoriesLevel from "../pages/Categories/CategoriesLevel";
import { fetchBrandsDatano } from "../pages/Brands/DataService";
import { Range } from "react-range";
import { debounce } from "lodash"; // Import lodash for debounce

function Filtre({
  marque,
  setMarque,
  categorie,
  setCategorie,
  categorieParente,
  setCategorieParente,
  min_price,
  setMin_price,
  max_price,
  setMax_price,
  min_stock,
  setMin_stock,
  max_stock,
  setMax_stock,
  handleResetFilters,
}) {
  const [values, setValues] = useState([min_price || 0, max_price || 10000]);
  const STEP = 1;
  const MIN = 0;
  const MAX = 10000;
  const [marquesOptions, setMarquesOptions] = useState([]);

  const handleCatParenteChange = (value) => setCategorieParente(value);
  const handleCatBasLevelChange = (value) => setCategorie(value);

  const fetchMarques = async () => {
    try {
      const marques = await fetchBrandsDatano();
      setMarquesOptions(marques);
    } catch (error) {
      console.error("Error fetching marques options:", error);
    }
  };

  const handleMarqueChange = (event) => setMarque(event.target.value);

  // // Debounced functions for min price, max price, and stock updates
  const debounceMinPrice = useCallback(
    debounce((value) => setMin_price(value), 300),
    []
  );
  const debounceMaxPrice = useCallback(
    debounce((value) => setMax_price(value), 300),
    []
  );


  // Update slider values and apply debounce
  const handleRangeChange = (newValues) => {
    setValues(newValues);
    debounceMinPrice(newValues[0]);
    debounceMaxPrice(newValues[1]);
  };

 // const handleStockChange = (event) => debounceStock(event.target.value);

  useEffect(() => {
    fetchMarques();
    setValues([min_price, max_price]);
  }, [min_price, max_price]);

  return (
    <>
      <Row>
        <Col md={3}>
          <Form.Group id="formMarqueId" className="pb-3">
            <Form.Label>Marque</Form.Label>
            <Form.Control
              as="select"
              id="brandSelect"
              className="form-select"
              onChange={handleMarqueChange}
              value={marque}
            >
              <option value="">Sélectionner une marque</option>
              {marquesOptions.map((marque) => (
                <option key={marque.id} value={marque.id}>
                  {marque.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>

        <CategoriesLevel
          onCatParenteChange={handleCatParenteChange}
          onCatBasLevelChange={handleCatBasLevelChange}
          categorie={categorie}
          setCategorie={setCategorie}
          categorieParente={categorieParente}
          setCategorieParente={setCategorieParente}
        />
      </Row>
      <Row className="pb-2">
        <Col Col md={2}>
          <Form.Group id="formQuantite" className="pb-3">
            <Form.Label>Quantité min</Form.Label>
            <Form.Control
              type="number"
               min="1"
              placeholder="Quantité min"
              value={min_stock}
              onChange={(e)=>setMin_stock(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col Col md={2}>
          <Form.Group id="formQuantite" className="pb-3">
            <Form.Label>Quantité max</Form.Label>
            <Form.Control
              type="number"
               min="1"
              placeholder="Quantité max"
              value={max_stock}
              onChange={(e)=>setMax_stock(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col Col md={3}>
          <label className="form-label">Prix</label>
          <div className="mt-10">
            <strong>
              Min: {values[0]} € - Max: {values[1]} €
            </strong>
          </div>
          <Range
            values={values}
            step={STEP}
            min={MIN}
            max={MAX}
            onChange={handleRangeChange}
            renderTrack={({ props, children }) => {
              // Calculate the percentage position of the min and max values
              const percentageMin = ((values[0] - MIN) / (MAX - MIN)) * 100;
              const percentageMax = ((values[1] - MIN) / (MAX - MIN)) * 100;

              return (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "10px",
                    width: "100%",
                    backgroundColor: "#C0C0C0",
                    background: `linear-gradient(
            to right,
            #ccc ${percentageMin}%,  // Light gray at min value
            #0044ab ${percentageMin}%, // Blue from min value
            #0044ab ${percentageMax}%, // Blue until max value
            #ccc ${percentageMax}%     // Light gray after max value
          )`,
                  }}
                >
                  {children}
                </div>
              );
            }}
            renderThumb={({ index, props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "37px",
                  width: "37px",
                  borderRadius: "50%",
                  backgroundColor: "#0044ab",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span style={{ color: "#fff", fontSize: "12px" }}>
                  {values[index]}
                </span>
              </div>
            )}
          />
        </Col>
        <Col Col md={3}>
          <div className="mt-41 text-center">
            <a
              href="#"
              className="btn btn-primary"
              onClick={handleResetFilters}
            >
              Tout effacer
            </a>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Filtre;
