import React from "react";
import validationCart from "../../../images/validationCart.svg";
import { Link } from "react-router-dom";
import { useAuth } from "../../../utils/AuthContext";

const ValidationOrder = () => {
  const { logout } = useAuth();
  const logOut = () => {
    localStorage.clear();
    window.location.href = "/login";
  };
  return (
    <div>
      <div className="bg-auth pt-40 ">
        <div className=" container d-flex align-items-center">
          <div className="row w-50 mx-auto justify-content-center align-items-center">
            <img
              src={validationCart}
              alt="order validate"
              className="w-auto mt-40"
            />
            <div className="row text-center mb-60">
              <h5>Merci, ta commande a bien été prise en compte !</h5>
              <p className="textConfirmation mb-3">
                Elle est en cours de traitement et sera validé par un de nos
                conseillers. Tu recevras un email de confirmation rapidement
              </p>
              <div>
                <Link className="btn btn-primary " to="/fournisseur/commande">
                  Passer une Nouvelle Commande
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidationOrder;
