import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Col } from "react-bootstrap";

const SearchBar = ({ searchTerm, handleSearch, name }) => {
  return (
   <>

      {name =="tourLivraison"?( 
          //  recherche dans la page tour de livraison 
         <Col md={6}>
            <input
              type="date" // Définition du type date-local
              id="date"
              name="date"
              className="form-control"
              value={searchTerm}
              onChange={(event) => handleSearch(event.target.value)} 
            />
        </Col> 
     ):(
      // recherche dans le reste des pages dans le bo
      <div className="search-bar d-flex align-items-center form-control">
               <input
        type="text"
        className=" form-control search-input-border"
        placeholder="Rechercher"
        value={searchTerm}
        onChange={(event) => handleSearch(event.target.value)} // Update search term
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleSearch(searchTerm); // Trigger search on Enter key
          }
        }}
      />
      <button
        className="search-with-filtre"
        type="submit"
        onClick={() => handleSearch(searchTerm)}
      >
        <FontAwesomeIcon icon={faSearch} size="1x" />
      </button>
      </div>


      )}
    </>

  );
};

export default SearchBar;
