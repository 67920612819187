// Importation des dépendances et composants nécessaires
import React, { useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Row, Col, Modal, Form, Button, Alert, Badge } from "react-bootstrap";
import ClickBack from "../../components/buttons/ClickBack";
import DynamicTable from "../../components/DynamicTable";
import {
  fetchCodePromoData,
  updateCodePromoData,
  saveCodePromoData,
  deleteCodePromo,
} from "./DataService";
import { fetchAllUserData } from "../users/dataService";
import IconEdit from "../../images/edit.png";
import { format } from "date-fns";
import { formatAllType } from "../../utilities";
import SearchBar from "../../components/SearchBar";

function ListScreen(props) {
  // Initialisation des hooks nécessaires

  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("add"); // Ajouter un état pour suivre le mode de la modale
  const [codePromoData, setCodePromoData] = useState(null);
  const [selectedCodePromo, setSelectedCodePromo] = useState(null);
  const [selectedCodePromoID, setSelectedCodePromoID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState(1); // Initial user role
  const [nbr, setNbr] = useState(1);
  const [error, setError] = useState(null); // État pour le message d'erreur
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRemise, setSelectedRemise] = useState("");
  const [newCodePromoData, setNewCodePromoData] = useState({
    title: "",
    code: "",
    reduction: "0",
    reduction_percent: "0",
    status: "",
    start_date: "",
    end_date: "",
    users: [],
    réutilisable: "",
    frais_de_port_gratuit: "",
    montant_minimum_panier: "",
  }); // État pour les nouvelles données de Code Promo
  const [userList, setUserList] = useState([]);

  const handleSelectChange = (e) => {
    setSelectedRemise(e.target.value);
  };

  // Fonction pour récupérer les données de Code Promo
  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await fetchCodePromoData(nbr, searchTerm);
      setLoading(false);
      if (data?.total && data?.total > 0) {
        setCodePromoData(data);
        console.log("codePromoData", data);
      } else {
        setCodePromoData(null);
        console.log("error");
      }
    } catch (error) {
      setError("Erreur lors de la récupération des données de Code Promo.");
      console.error(
        "Erreur lors de la récupération des données de Code Promo :",
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const users = await fetchAllUserData(); // Appeler la fonction pour récupérer les utilisateurs depuis la base de données
      setUserList(users); // Mettre à jour l'état avec la liste des utilisateurs
    } catch (error) {
      console.error("Erreur lors de la récupération des utilisateurs :", error);
    }
  };

  // Update search term and fetch data
  const handleSearch = (term) => {
    setSearchTerm(term);
    setNbr(1); // Reset to first page
  };
  // useEffect pour charger automatiquement les données lorsque le composant est monté
  useEffect(() => {
    fetchData();
    fetchUsers();
  }, [nbr, searchTerm]);
  const handleModalOptionClick = (codepromoID) => {
    // Mettre à jour l'identifiant de Code Promo sélectionné
    setSelectedCodePromoID(codepromoID);
    // S'assurer que codepromoID et codepromoID.original sont définis
    if (codepromoID && codepromoID.original) {
      // Mettre à jour les données du code promo sélectionné dans l'état
      setSelectedCodePromo(codepromoID.original);
      // Déterminer le type de remise et mettre à jour selectedRemise
      const { reduction, reduction_percent } = codepromoID.original;
      if (reduction !== "0.00") {
        setSelectedRemise("1"); // remise absolue
      } else if (reduction_percent !== "0.00") {
        setSelectedRemise("2"); // remise en pourcentage
      }
    } else {
      // Si codepromoID ou codepromoID.original ne sont pas définis, réinitialiser selectedCodePromoID et selectedCodePromo à null
      // setSelectedCodePromoID(null);
      setSelectedCodePromo(null);
    }

    // Puis procéder à la définition de showModal et modalMode
    setShowModal(true);
    setModalMode("edit"); // Définir le mode de la modale sur modification
  };

  // Fonction pour ouvrir la modale d'ajout de livraison
  const handleOpenAddDeliveryModal = () => {
    setShowModal(true);
    setModalMode("add"); // Définir le mode de la modale sur ajout
  };
  // Fonction pour fermer la modale
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCodePromo(null);
    setNewCodePromoData({
      title: "",
      code: "",
      reduction: "",
      reduction_percent: "",
      status: "",
      users: [],
      réutilisable: "",
      frais_de_port_gratuit: "",
      montant_minimum_panier: "",
    });
    setError(null); // Effacer le message d'erreur lorsque la modale est fermée
  };

  const handleNewCodePromoChange = (fieldName, value) => {
    if (modalMode === "add") {
      // For adding a new Code Promo
      setNewCodePromoData((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
    } else {
      // For updating an existing Code Promo
      setSelectedCodePromo((prevDelivery) => ({
        ...prevDelivery,
        [fieldName]: value,
      }));
    }
  };

  const handleUserSelection = (selectedUserIds) => {
    const selectedUsers = userList.filter((user) =>
      selectedUserIds.includes(user.id.toString())
    );
    if (modalMode === "add") {
      // For adding a new Code Promo
      setNewCodePromoData((prevData) => ({
        ...prevData,
        users: selectedUsers,
        user_ids: selectedUserIds,
      }));
    } else {
      // For updating an existing Code Promo
      setSelectedCodePromo((prevData) => ({
        ...prevData,
        users: selectedUsers,
        user_ids: selectedUserIds,
      }));
    }
  };

  // Fonction pour gérer l'archivage' de Code Promo
  const handleCodePromoArchive = async (codepromoID) => {
    // Implémentation pour l'archivage du code promo
    try {
      const response = await deleteCodePromo(codepromoID);
      if (response.status === 200) {
        handleCloseModal();
        history.go(0);
      } else {
        throw new Error(
          `Échec de l'archivage du Code Promo. Statut : ${response.status}`
        );
      }
    } catch (error) {
      setError(error.message);
      console.error("Erreur lors de de l'archivage du Code Promo :", error);
    }
  };

  // Fonction pour mettre à jour les données du Code Promo
  const handleUpdateCodePromo = async () => {
    // Implémentation pour la mise à jour des données du Code Promo
    try {
      const formData = new FormData();
      if (selectedRemise === "1" || selectedRemise === 1) {
        selectedCodePromo.reduction_percent = 0;
      } else {
        selectedCodePromo.reduction = 0;
      }
      formData.append("Titre", selectedCodePromo.title);
      formData.append("Code", selectedCodePromo.code);
      formData.append("Date Début", selectedCodePromo.start_date);
      formData.append("Date Fin", selectedCodePromo.end_date);
      formData.append("réutilisable", selectedCodePromo.réutilisable);
      formData.append(
        "frais_de_port_gratuit",
        selectedCodePromo.frais_de_port_gratuit
      );
      formData.append(
        "montant_minimum_panier",
        selectedCodePromo.montant_minimum_panier
      );
      formData.append("id", selectedCodePromoID?.original.id);
      // Ajout des bénéficiaires sélectionnés
      formData.append(
        "user_ids",
        selectedCodePromo.users.map((user) => user.id).join(", ")
      );
      setLoading(false);
      // Validation des champs obligatoires

      const startDate = new Date(selectedCodePromo.start_date);
      const endDate = new Date(selectedCodePromo.end_date);

      if (startDate >= endDate) {
        throw new Error(
          "La date de début doit être antérieure à la date de fin."
        );
      }

      if (
        parseFloat(selectedCodePromo.reduction) === 0 &&
        parseFloat(selectedCodePromo.reduction_percent) === 0 &&
        selectedCodePromo.frais_de_port_gratuit === "NON"
      ) {
        throw new Error(
          "Un des champs Somme absolue ou Somme en % doit contenir un valeur positive ou bien un frais de port gratuit"
        );
      }

      const response = await updateCodePromoData(
        selectedCodePromoID.original.id,
        selectedCodePromo
      );

      if (response.status === 200) {
        handleCloseModal();
        history.go(0);
      } else {
        throw new Error("Erreur lors de la mise à jour.");
      }
    } catch (error) {
      if (error?.response?.data.message) {
        setError(error?.response?.data.message);
      } else {
        setError(error?.message);
      }
      console.error("Erreur lors de la mise à jour du Code Promo :", error);
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour ajouter un Code Promo
  const handleAddCodePromo = async () => {
    // Vérifier si newCodePromoData est défini et non vide
    try {
      setLoading(true);
      const formData = new FormData();
      if (selectedRemise === "1" || selectedRemise === 1) {
        newCodePromoData.reduction_percent = 0;
      } else {
        newCodePromoData.reduction = 0;
      }
      formData.append("Titre", newCodePromoData.title);
      formData.append("Code", newCodePromoData.code);
      formData.append("Etat", newCodePromoData.status);
      formData.append(
        "user_ids",
        newCodePromoData.users.map((user) => user.id).join(", ")
      ); // Utilisez join(", ") pour ajouter un espace après chaque virgule

      formData.append("Date Début", newCodePromoData.start_date);
      formData.append("Date Fin", newCodePromoData.end_date);
      // Validation des champs obligatoires
      if (
        !newCodePromoData.title ||
        !newCodePromoData.code ||
        !newCodePromoData.status ||
        !newCodePromoData.start_date ||
        !newCodePromoData.end_date
      ) {
        throw new Error("Tous les champs sont obligatoires.");
      }
      const startDate = new Date(newCodePromoData.start_date);
      const endDate = new Date(newCodePromoData.end_date);

      if (startDate >= endDate) {
        throw new Error(
          "La date de début doit être antérieure à la date de fin."
        );
      }

      if (
        parseFloat(newCodePromoData.reduction) === 0 &&
        parseFloat(newCodePromoData.reduction_percent) === 0 &&
        newCodePromoData.frais_de_port_gratuit === "NON"
      ) {
        throw new Error(
          "Un des champs Somme absolue ou Somme en % doit contenir un valeur positive ou bien un frais de port gratuit "
        );
      }

      const response = await saveCodePromoData(newCodePromoData);
      if (response.status === 201) {
        handleCloseModal();
        history.go(0);
      } else {
        throw new Error("Erreur lors de l'ajout.");
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        setError(error?.response?.data?.message);
      } else {
        setError(error?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  // Colonnes mémorisées pour le composant DynamicTable
  const columns = useMemo(
    () => [
      { Header: "Titre", accessor: "title" },
      { Header: "Code", accessor: "code" },
      {
        Header: "Somme absolue",
        accessor: "reduction",
        Cell: ({ value }) => formatAllType(value),
      },
      { Header: "Somme en %", accessor: "reduction_percent" },
      {
        Header: "Statut",
        accessor: "status",
        Cell: ({ value }) => (
          <Badge
            className={
              value === "archived"
                ? "bg-secondary"
                : value === "active"
                ? "bg-success"
                : value === "expired"
                ? "bg-danger"
                : "bg-light"
            }
          >
            {value === "archived"
              ? "Archivé"
              : value === "active"
              ? "Active"
              : value === "expired"
              ? "Expiré"
              : value}
          </Badge>
        ),
      },
      {
        Header: "Bénéficiaires",
        accessor: (row) => (
          <span>
            {row.users.length > 0 ? (
              <>
                {row.users.length > 3 ? (
                  <>
                    <p>{`${row.users[0].prenom} ${row.users[0].nom}`}</p>
                    <p>{`${row.users[1].prenom} ${row.users[1].nom}`}</p>
                    <p>{`${row.users[2].prenom} ${row.users[2].nom}`}</p>
                    <p>...</p>
                  </>
                ) : (
                  row.users.map((user) => (
                    <p key={user.id}>{`${user.prenom} ${user.nom}`}</p>
                  ))
                )}
              </>
            ) : (
              "Tout le monde"
            )}
          </span>
        ),
      },
      {
        Header: "Date début",
        accessor: "start_date",
        Cell: ({ value }) => (
          <span>
            {value ? format(new Date(value), "dd-MM-yyyy HH:mm") : ""}
          </span>
        ),
      },
      {
        Header: "Date fin",
        accessor: "end_date",
        Cell: ({ value }) => (
          <span>
            {value ? format(new Date(value), "dd-MM-yyyy HH:mm") : ""}
          </span>
        ),
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ value, row }) => (
          <div>
            {/* Icône d'édition avec un gestionnaire de clic */}
            <a
              href="#"
              className="p-2"
              onClick={() => handleModalOptionClick(row)}
            >
              <img src={IconEdit} width="20px" alt="Supprimée" />
            </a>
          </div>
        ),
      },
    ],
    []
  );

  // Rendu du message de chargement pendant que les données sont récupérées
  if (!codePromoData) {
    return (
      <Container>
        <p>Chargement des données des Codes promo...</p>
      </Container>
    );
  }

  // Rendu du composant
  return (
    <Container>
      <div className="CodePomoList">
        {/* Navigation par breadcrumb */}
        <p className="h6">Accueil / Code Promo</p>

        {/* En-tête et filtres */}
        <Row className="pt-4 pb-2">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <ClickBack />
            {/* Afficher le titre dynamique */}
            <h1 className="p-1">Code Promo</h1>
          </Col>
          <Col
            xs
            lg="3"
            className="d-flex justify-content-end align-items-center"
            style={{ alignContent: "right" }}
          >
            <Button variant="primary" onClick={handleOpenAddDeliveryModal}>
              Ajouter un Code Promo
            </Button>
          </Col>
        </Row>
        <SearchBar searchTerm={searchTerm} handleSearch={handleSearch} />
        {/* Rendu du composant DynamicTable */}
        {loading ? (
          // Affichage du chargeur pendant que les données sont récupérées
          <p className="lead">Chargement...</p>
        ) : (
          <DynamicTable
            columns={columns}
            data={codePromoData?.data}
            link={codePromoData?.links}
            last_page={codePromoData?.last_page}
            setNbr={setNbr}
            nbr={nbr}
            total={codePromoData?.total}
            name="codePromo"
          />
        )}
      </div>

      {/* Modale Bootstrap */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalMode === "add"
              ? "Ajouter un nouveau Code Promo"
              : "Modifier/Archiver Code Promo : " +
                (selectedCodePromoID ? selectedCodePromoID.original.id : "")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group controlId="title" className="mb-2">
                  <Form.Label>Titre :</Form.Label>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    className="form-control"
                    value={
                      modalMode === "edit"
                        ? selectedCodePromo?.title
                        : newCodePromoData.title
                    }
                    onChange={(e) =>
                      handleNewCodePromoChange("title", e.target.value)
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formcode" className="mb-2">
                  <Form.Label>Code :</Form.Label>
                  <input
                    type="text"
                    id="code"
                    name="code"
                    className="form-control"
                    value={
                      modalMode === "edit"
                        ? selectedCodePromo?.code
                        : newCodePromoData.code
                    }
                    onChange={(e) =>
                      handleNewCodePromoChange("code", e.target.value)
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formRemise">
                  <Form.Label>Remise</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    className="form-control"
                    value={selectedRemise}
                    name="role"
                    onChange={handleSelectChange}
                  >
                    <option value="" disabled hidden>
                      Sélectionnez le type
                    </option>
                    <option value="1">montant</option>
                    <option value="2">pourcentage</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              {selectedRemise === "1" && (
                <Col md={6}>
                  <Form.Group controlId="sommeAbsolue" className="mb-2">
                    <Form.Label>Somme absolue :</Form.Label>
                    <input
                      type="text"
                      id="reduction"
                      name="reduction"
                      className="form-control"
                      placeholder="0.00"
                      defaultValue="0"
                      value={
                        modalMode === "edit"
                          ? selectedCodePromo?.reduction
                          : newCodePromoData.reduction
                      }
                      onChange={(e) =>
                        handleNewCodePromoChange("reduction", e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
              )}

              {selectedRemise === "2" && (
                <Col md={6}>
                  <Form.Group controlId="reduction_percent" className="mb-2">
                    <Form.Label>Somme en % :</Form.Label>
                    <input
                      type="text"
                      id="reduction_percent"
                      name="reduction_percent"
                      className="form-control"
                      placeholder="0.00"
                      defaultValue="0"
                      value={
                        modalMode === "edit"
                          ? selectedCodePromo?.reduction_percent
                          : newCodePromoData.reduction_percent
                      }
                      onChange={(e) =>
                        handleNewCodePromoChange(
                          "reduction_percent",
                          e.target.value
                        )
                      }
                    />
                  </Form.Group>
                </Col>
              )}
            </Row>
            <Row>
              <Col md={6}>
                <Form.Label>Date Début :</Form.Label>
                <input
                  type="datetime-local" // Définition du type datetime-local
                  id="start_date"
                  name="start_date"
                  className="form-control"
                  value={
                    modalMode === "edit"
                      ? selectedCodePromo?.start_date
                      : newCodePromoData.start_date
                  }
                  onChange={(e) =>
                    handleNewCodePromoChange("start_date", e.target.value)
                  } // Gestionnaire d'événements pour mettre à jour la valeur
                />
              </Col>
              <Col md={6}>
                <Form.Label>Date Fin :</Form.Label>
                <input
                  type="datetime-local" // Définition du type datetime-local
                  id="end_date"
                  name="end_date"
                  className="form-control"
                  value={
                    modalMode === "edit"
                      ? selectedCodePromo?.end_date
                      : newCodePromoData.end_date
                  }
                  onChange={(e) =>
                    handleNewCodePromoChange("end_date", e.target.value)
                  } // Gestionnaire d'événements pour mettre à jour la valeur
                />
              </Col>
            </Row>
            <Row>
              <Form.Group controlId="userSelection" className="mb-2">
                <Form.Label>Sélectionnez les bénéficiaires :</Form.Label>
                <Form.Control
                  as="select"
                  multiple
                  value={
                    modalMode === "edit" && selectedCodePromo?.users
                      ? selectedCodePromo.users.map((user) => user.id)
                      : newCodePromoData.users.map((user) => user.id)
                  }
                  onChange={(e) =>
                    handleUserSelection(
                      Array.from(
                        e.target.selectedOptions,
                        (option) => option.value
                      )
                    )
                  }
                >
                  {userList.length > 0 &&
                    userList.map((user) => (
                      <option key={user.id} value={user.id}>
                        {`${user.prenom} ${user.nom}`}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="réutilisable" className="mb-2">
                  <Form.Label>Réutilisable :</Form.Label>
                  <Form.Control
                    as="select"
                    name="réutilisable"
                    value={
                      modalMode === "edit"
                        ? selectedCodePromo?.réutilisable
                        : newCodePromoData.réutilisable
                    }
                    onChange={(e) =>
                      handleNewCodePromoChange("réutilisable", e.target.value)
                    }
                  >
                    <option value="" disabled>
                      Sélectionner Réutilisable
                    </option>
                    <option value="OUI">OUI</option>
                    <option value="NON">NON</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="frais_de_port_gratuit" className="mb-2">
                  <Form.Label>Frais de port gratuit :</Form.Label>
                  <Form.Control
                    as="select"
                    name="frais_de_port_gratuit"
                    value={
                      modalMode === "edit"
                        ? selectedCodePromo?.frais_de_port_gratuit
                        : newCodePromoData.frais_de_port_gratuit
                    }
                    onChange={(e) =>
                      handleNewCodePromoChange(
                        "frais_de_port_gratuit",
                        e.target.value
                      )
                    }
                  >
                    <option value="" disabled>
                      Sélectionner Frais de port gratuit
                    </option>
                    <option value="OUI">OUI</option>
                    <option value="NON">NON</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="montant_minimum_panier" className="mb-2">
                  <Form.Label>Montant minimum du panier :</Form.Label>
                  <Form.Control
                    type="number"
                    name="montant_minimum_panier"
                    placeholder="0.00"
                    value={
                      modalMode === "edit"
                        ? selectedCodePromo?.montant_minimum_panier
                        : newCodePromoData.montant_minimum_panier
                    }
                    onChange={(e) =>
                      handleNewCodePromoChange(
                        "montant_minimum_panier",
                        e.target.value
                      )
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="status" className="mb-2">
                  <Form.Label>Etat :</Form.Label>
                  <Form.Control
                    as="select"
                    name="status"
                    value={
                      modalMode === "edit"
                        ? selectedCodePromo?.status
                        : newCodePromoData.status
                    }
                    onChange={(e) =>
                      handleNewCodePromoChange("status", e.target.value)
                    }
                    required
                  >
                    <option value="" disabled>
                      Sélectionner le statut
                    </option>
                    <option value="active">Actif</option>
                    <option value="expired">Expiré</option>
                    <option value="archived">Archivé</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fermer
          </Button>
          {modalMode === "edit" && (
            <Button
              variant="danger"
              onClick={() =>
                handleCodePromoArchive(
                  selectedCodePromoID ? selectedCodePromoID.original.id : ""
                )
              }
            >
              Archiver
            </Button>
          )}

          <Button variant="primary">
            <Button
              variant="primary"
              onClick={
                modalMode === "add" ? handleAddCodePromo : handleUpdateCodePromo
              }
            >
              {modalMode === "add"
                ? "Ajouter"
                : "Enregistrer les modifications"}
            </Button>
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ListScreen;
