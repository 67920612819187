// dataService.js
import api from '../../utils/ApiConfig';

// Fetch all categories form database ************************************************************
const fetchCategoriesData = async (nbr,termSearch) => {
  try {
    const response = await api.get(`/bo/categories?per_page=50&page=${nbr}&search_term=${termSearch}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// Update category status  ************************************************************
const updateCatogoryStatus = async (CategoryId) => {
  try {
    const response = await api.delete(`categories/delete/${CategoryId}}`);
    
    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Check if the response data is valid and return the updated category data
        return response;
    } else {
      throw new Error(`Failed to update category status. Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error updating category status:', error);
    throw error;
  }
};
// Update category data  ************************************************************
const updateCategoryData = async (categorytData) => {
  try {
    const response = await api.post(`/categories/update`, categorytData);
    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Check if the response data is valid and return the updated category data
      return response;
    } else {
      throw new Error(`Failed to update category data. Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error updating category data:', error);
    throw error;
  }
};
// Save category data  ************************************************************
const saveCategoryData = async (categoryData) => {
  try {
    const response = await api.post(`/categories/create`, categoryData);
    // Check if the response status is 201 (Created)
    if (response.status === 201) {
      // Check if the response data is valid and return the updated category data
      return response;
    } else {
      throw new Error(`Failed to add category. Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error creating category data:', error);
    throw error;
  }
};


// Export methods ************************************************************
export { fetchCategoriesData ,  updateCatogoryStatus , updateCategoryData , saveCategoryData };