import React, { useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Row, Col, Modal, Form, Button, Alert } from "react-bootstrap";
import ClickBack from "../../components/buttons/ClickBack";
import DynamicTable from "../../components/DynamicTable";
import {
  fetchCategoriesData,
  updateCatogoryStatus,
  updateCategoryData,
  saveCategoryData,
} from "./DataService";
import IconEdit from "../../images/edit.png";
import SearchBar from "../../components/SearchBar";

// Définir le composant fonctionnel
function ListScreen(props) {
  // Initialiser les hooks nécessaires
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("add"); // Ajouter un état pour suivre le mode modal
  const [categoryData, setCategoryData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryID, setSelectedCategoryID] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageName, setImageName] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [locationSubCategorie, setLocationSubCategorie] = useState(false); //definier url location pour sous categories
  const [error, setError] = useState(null);
  const [basNiveau, setBasNiveau] = useState(false);
  const [selectedSubcategoryTitle, setSelectedSubcategoryTitle] =
    useState(null); // Etat pour suivre arborésence du titre
  const [searchTerm, setSearchTerm] = useState("");
  const [newCategoryData, setNewCategoryData] = useState({
    name: "",
    slug: "",
    tva: "",
  });
  const [nbr, setNbr] = useState(1);

  // Fonction pour gérer le téléchargement d'image
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    setImageName(file.name);
  };

  // Fonction pour gérer les modifications d'entrée pour les données de catégorie nouvelles ou sélectionnées
  const handleCategoryChange = (event) => {
    const { name, value } = event.target;
    if (modalMode === "add") {
      setNewCategoryData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      setSelectedCategoryID((prevRow) => ({
        ...prevRow,
        original: {
          ...prevRow.original,
          [name]: value,
        },
      }));
    }
  };

  // Fonction pour ouvrir la modal d'ajout de catégorie
  const handleOpenAddCategoryModal = () => {
    setShowModal(true);
    setModalMode("add");
  };

  // Fonction pour gérer le clic sur une option modale
  const handleModalOptionClick = (categoryID) => {
    setSelectedCategoryID(categoryID);
    if (categoryID.values.subcategories.length > 0) {
      setBasNiveau(false);
    } else {
      setBasNiveau(true);
    }
    setShowModal(true);
    setModalMode("edit");
  };
  // Fonction pour recharger les données et les colonnes
  const reloadData = (newData) => {
    setCategoryData(newData);
    setNewCategoryData(newData);
    // Vous pouvez également mettre à jour les colonnes si nécessaire
  };
  // Fonction pour gérer la sélection de sous-catégorie
  const handleOptionClick = (list, name) => {
    const subcategory = list.find((child) => {
      return child.name == name;
    });
    setSelectedCategory(subcategory);
    setSelectedSubcategoryTitle(subcategory);
    // Vérifier si la sous-catégorie sélectionnée a des sous-catégories
    if (subcategory.subcategories && subcategory.subcategories.length > 0) {
      // Recharger le tableau avec de nouvelles données (remplacer par votre logique de chargement de données réelle)
      reloadData(subcategory.subcategories);
      setLocationSubCategorie(true);
    } else {
      // S'il n'y a qu'une seule sous-catégorie et qu'elle n'a pas de sous-catégories, recharger le tableau avec cette sous-catégorie
      reloadData([subcategory]);
    }
  };

  // Fonction pour gérer l'ajout d'une nouvelle catégorie
  const handleAddCategory = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", newCategoryData.name);
      formData.append("slug", newCategoryData.slug);
      formData.append("tva", newCategoryData.tva);
      formData.append("image", imageFile);
      formData.append("metier", "1");
      formData.append("parent_id", "");
      // Vérifier s'il s'agit d'une sous-catégorie et définir parent_id en conséquence
      if (modalMode === "add" && newCategoryData.parentCategory) {
        formData.append("parent_id", newCategoryData.parentCategory);
        formData.append("metier", "0");
      }
      const response = await saveCategoryData(formData);
      if (response.status === 201) {
        // Recharger le tableau après avoir ajouté une nouvelle catégorie
        fetchData(1);
        handleCloseModal();
        history.go(0);
      } else {
        throw new Error(
          `Échec de l'ajout de la catégorie. Statut : ${response.status}`
        );
      }
    } catch (error) {
      console.error("Erreur lors de l`ajout de la catégorie :", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  // Fonction pour mettre à jour les données de la catégorie
  const handleUpdateCategory = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      if (selectedCategoryID && selectedCategoryID.original) {
        formData.append("name", selectedCategoryID.original.name);
        formData.append("slug", selectedCategoryID.original.slug);
        formData.append("tva", selectedCategoryID.original.tva);
        formData.append("id", parseInt(selectedCategoryID.original.id));
        if (imageFile) {
          formData.append("image", imageFile);
        }
        formData.append(
          "parent_id",
          selectedCategoryID?.original?.parent_id
            ? parseInt(selectedCategoryID.original.parent_id)
            : ""
        );
      }
      const response = await updateCategoryData(formData);
      if (response.status === 200) {
        handleCloseModal();
        history.go(0);
      } else {
        throw new Error(
          `Échec de la mise à jour de la catégorie. Statut : ${response.status}`
        );
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la catégorie :", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour gérer la suppression de catégories
  const handleCategoryDelete = async (categoryID) => {
    try {
      const response = await updateCatogoryStatus(categoryID);
      if (response.status === 200) {
        handleCloseModal();
        history.go(0);
      } else {
        throw new Error(
          `Échec de la suppression de la catégorie. Statut : ${response.status}`
        );
      }
    } catch (error) {
      setError(error.message);
    }
  };

  // Fonction pour fermer la modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCategory(null);
    setError(null);
    setNewCategoryData({
      name: "",
      slug: "",
      tva: "",
    });
  };

  // Fonction pour récupérer les données de catégorie
  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await fetchCategoriesData(nbr, searchTerm);
      setCategoryData(data.categories);
      setPagination(data);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des données de catégorie :",
        error
      );
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  // Fonction récursive pour construire le chemin complet jusqu'à une catégorie
  const buildCategoryPath = (category, path = []) => {
    // Ajouter le nom de la catégorie actuelle au chemin
    path.unshift(category.name);

    // Vérifier s'il y a une catégorie parente
    if (category.parentCategory) {
      // Si oui, rappeler la fonction récursivement avec la catégorie parente
      return buildCategoryPath(category.parentCategory, path);
    }

    // Si la catégorie n'a pas de parent, cela signifie que nous sommes arrivés à la catégorie racine
    return path;
  };

  // Update search term and fetch data
  const handleSearch = (term) => {
    setSearchTerm(term);
    setNbr(1); // Reset to first page
  };
  // useEffect pour charger automatiquement les données lorsque le composant est monté
  useEffect(() => {
    fetchData(nbr);
  }, [nbr, searchTerm]);
  // Colonnes mémorisées pour le composant DynamicTable
  const columns = useMemo(
    () => [
      // Informations sur la catégorie
      { Header: "Nom", accessor: "name" },
      // Nouvelle colonne pour compter le nombre de sous-catégories
      {
        Header: "Sous-catégorie",
        accessor: "subcategories.length",
      },
      // Nouvelles colonnes pour le tableau des sous-catégories
      {
        Header: "Sous-catégories",
        accessor: "subcategories",
        Cell: ({ value }) => {
          return (
            <Form.Control
              as="select"
              disabled={!value || value.length === 0}
              onChange={(e) => {
                handleOptionClick(value, e.target.value);
              }}
            >
              <option>Sélectionner une sous-catégorie</option>
              {value &&
                value.map((subcategory, index) => (
                  <option
                    key={index}
                    value={subcategory.name}
                    onClick={() => handleOptionClick(subcategory)}
                  >
                    {subcategory.name}
                  </option>
                ))}
            </Form.Control>
          );
        },
      },
      // Colonne d'actions
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ value, row }) => (
          <div>
            {/* Icône de modification avec un gestionnaire de clic */}
            <a className="p-2" onClick={() => handleModalOptionClick(row)}>
              <img src={IconEdit} width="20px" alt="Modifier" />
            </a>
          </div>
        ),
      },
    ],
    []
  );

  // Rendre un message de chargement pendant le chargement des données
  if (!categoryData) {
    return (
      <Container>
        <p>Chargement...</p>
      </Container>
    );
  }

  // Rendre le composant
  return (
    <Container>
      <div className="CategoryList">
        {/* Navigation par fil d'Ariane */}
        {/* <p className="h6">Accueil / Catégories 
         
         {selectedSubcategoryTitle && <>&nbsp;/&nbsp;{selectedSubcategoryTitle.name} </>}
        </p> */}

        <p className="h6">
          Accueil / Catégories
          {selectedSubcategoryTitle && (
            <>
              {buildCategoryPath(selectedSubcategoryTitle).map(
                (category, index, array) => (
                  <React.Fragment key={index}>
                    <span>/&nbsp;{category}</span>
                    {/* Ajouter un séparateur sauf pour la dernière catégorie */}
                    {index !== array.length - 1 && <span>&nbsp;/&nbsp;</span>}
                  </React.Fragment>
                )
              )}
            </>
          )}
        </p>

        {/* En-tête et filtres */}
        <Row className="pt-4 pb-2">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <ClickBack locationSubCategorie={locationSubCategorie} />
            {/* Afficher le titre dynamique */}
            <h1 className="p-1">Catégories</h1>
          </Col>
          <Col
            xs
            lg="2"
            className="d-flex justify-content-end align-items-center"
            style={{ alignContent: "right" }}
          >
            {/* Bouton pour ouvrir la modal d'ajout de catégorie */}
            <Button variant="primary" onClick={handleOpenAddCategoryModal}>
              Ajouter catégorie
            </Button>
          </Col>
        </Row>
        <SearchBar searchTerm={searchTerm} handleSearch={handleSearch} />
        {/* Rendre le composant DynamicTable */}
        {loading ? (
          // Afficher le chargeur pendant le chargement des données
          <p className="lead">Chargement...</p>
        ) : (
          <DynamicTable
            columns={columns}
            data={categoryData}
            last_page={pagination.pagination.last_page}
            setNbr={setNbr}
            nbr={nbr}
            total={pagination.pagination.total}
            name="categorie"
          />
        )}
      </div>
      {/* Modal Bootstrap */}
      {/* Modal de modification/ajout */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          {/* Titre de la modal */}
          <Modal.Title>
            {modalMode === "edit" ? "Modifier" : "Ajouter"} une catégorie
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Contenu de la modal */}
          {error && <Alert variant="danger">{error}</Alert>}
          <Form.Group controlId="formParentCategory" className="mb-2">
            <Form.Label hidden={modalMode !== "add"}>
              Catégorie parente
            </Form.Label>
            <Form.Control
              as="select"
              name="parentCategory"
              onChange={handleCategoryChange}
              hidden={modalMode !== "add"}
            >
              <option value="">Aucune</option>
              {categoryData.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form>
            <Form.Group controlId="formCategoryName" className="mb-2">
              <Form.Label>Nom de la catégorie</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entrez le nom de la catégorie"
                name="name"
                value={
                  modalMode === "edit"
                    ? selectedCategoryID?.original.name
                    : newCategoryData.name
                }
                onChange={handleCategoryChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formSlug" className="mb-2">
              <Form.Label>Slug</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entrez le slug de la catégorie"
                name="slug"
                value={
                  modalMode === "edit"
                    ? selectedCategoryID?.original.slug
                    : newCategoryData.slug
                }
                onChange={handleCategoryChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formImageUpload" className="mb-2">
              <Form.Label>Image</Form.Label>
              <Form.Control
                required
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
              />
              {imageName && <p>Image sélectionnée : {imageName}</p>}
            </Form.Group>
            {basNiveau && (
              <Form.Group controlId="formMarge" className="mb-2">
                <Form.Label>Marge</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Entrez la valeur"
                  name="tva"
                  value={
                    modalMode === "edit"
                      ? selectedCategoryID?.original.tva
                      : newCategoryData.tva
                  }
                  onChange={handleCategoryChange}
                  required
                />
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {/* Pied de modal */}
          <Button variant="secondary" onClick={handleCloseModal}>
            Fermer
          </Button>
          {modalMode === "edit" && (
            <div>
              <Button
                variant="danger"
                onClick={() =>
                  handleCategoryDelete(
                    selectedCategoryID ? selectedCategoryID.original.id : ""
                  )
                }
              >
                Supprimer
              </Button>
              <Button variant="primary" onClick={handleUpdateCategory}>
                Enregistrer les modifications
              </Button>
            </div>
          )}
          {modalMode === "add" && (
            <Button variant="primary" onClick={handleAddCategory}>
              Enregistrer
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

// Exporter le composant comme exportation par défaut
export default ListScreen;
